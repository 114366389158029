import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history';
import user from "stores/user";

const history = createBrowserHistory();

const middlewares = [thunk, routerMiddleware(history)];

const reducer = combineReducers({
    user,
    router: connectRouter(history),
});

const store = (initialState) => createStore(
    reducer,
    initialState,
    process.env.NODE_ENV !== 'production' ?
        composeWithDevTools(applyMiddleware(...middlewares))
        :
        applyMiddleware(...middlewares)
);

export { history };

export default store();
