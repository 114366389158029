import React from "react";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import styles from "./styles.scss";
import Table from "react-bootstrap/Table";
import Loading from "components/Loading";
import Modal from 'react-modal';
import Moment from 'react-moment';
import 'react-toastify/dist/ReactToastify.css';
import MediaQuery from "react-responsive";
import DefaultLoading from "components/DefaultLoading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faFileCsv, faChartPie, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import Popup from "reactjs-popup";
import './styles.css';
import { RadioButton, RadioGroup } from '@trendmicro/react-radio';
import '@trendmicro/react-radio/dist/react-radio.css';

const TimeCard = props => (
    <div className={styles.wrapper}>
        {props.loading ?
            <Loading {...props} />
            :
            <Row className={styles.timecardRow}>
                <Col md={{ span: 12 }} xs={{ span: 12 }} className={styles.head}>

                    <h5 >タイムカード</h5>
                </Col>
                <MediaQuery maxDeviceWidth={480}>
                    {matches => {
                        // desktop版
                        if (!matches) {
                            return <>
                                <Col md={{ span: 11, offset: 1 }} >
                                    <div className={classNames(styles.range)}>
                                        {props.rangeInfo ?
                                            props.rangeInfo.startDate + " 〜 " + props.rangeInfo.endDate + "までの出勤情報を表示します"
                                            : <span>期間取得に失敗しました</span>
                                        }
                                    </div>
                                </Col>
                                <Col md={{ span: 5, offset: 1 }} >
                                    <Table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th>稼働日数</th>
                                                <th>稼働時間</th>
                                                <th>時間外</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{props.workDayCount}</td>
                                                <td>{props.totalTime}</td>
                                                <td>{props.overTime}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={{ span: 6 }} />
                                <Col md={{ span: 10, offset: 1 }} >
                                    <div className={styles.timeCard}>
                                        <Row className={styles.timeCardButtons}>
                                            {props.workList.length > 0 &&
                                                <Col md={{ span: 12 }} >
                                                    <Form className={styles.monthlyCommentForm}>
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>月次コメント入力</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="monthlyComment"
                                                                value={props.monthlyComment}
                                                                onChange={props.handleChange}
                                                                maxLength={40}
                                                            />
                                                        </Form.Group>
                                                        <Button type='sumbit' variant="primary" onClick={props.handleCreateMonthlyComment}>月次コメント登録</Button>
                                                    </Form>
                                                </Col>
                                            }
                                            <Col md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }}>
                                                <Button
                                                    onClick={props.openModal}
                                                    className={styles.attendanceErrorButton}>編集</Button>
                                                <Modal
                                                    isOpen={props.modalIsOpen}
                                                    onRequestClose={props.closeModal}
                                                    contentLabel="Example Modal"
                                                    shouldCloseOnOverlayClick={true}
                                                    style={{
                                                        overlay: {
                                                            zIndex: 999999999
                                                        },
                                                        content: {
                                                            border: 'none',
                                                            maxWidth: '500px',
                                                            maxHeight: '400px',
                                                            margin: 'auto'
                                                        },

                                                    }}
                                                >
                                                    <div>
                                                        <div className="form-group">
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Label>日付</Form.Label>
                                                                <Form.Control as="select"
                                                                    name="manualWorkDate"
                                                                    value={props.manualWorkDate}
                                                                    onChange={props.handleChange}>
                                                                    <option value={""}>選択してください</option>
                                                                    {props.workList && props.workList.length &&
                                                                        props.workList.map((work, index) => (
                                                                            <option key={index} value={work.workDate}>{work.workDate}</option>
                                                                        ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <RadioGroup
                                                                name="which"
                                                                value={props.which}
                                                                onChange={props.handleRadioClick}
                                                                depth={3}
                                                            >
                                                                <Row>
                                                                    <Col>
                                                                        <RadioButton label="出勤" value="punchIn" />
                                                                    </Col>
                                                                    <Col>
                                                                        <RadioButton label="退勤" value="punchOut" />
                                                                    </Col>
                                                                </Row>
                                                            </RadioGroup>
                                                            <Form.Group >
                                                                <Form.Label>時間</Form.Label>
                                                                <Form.Control
                                                                    type="time"
                                                                    name="manualWorkTime"
                                                                    value={props.manualWorkTime}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </Form.Group>

                                                            <div className="text-right">
                                                                <Button className="btn btn-secondary my-2" onClick={props.handleCalcManualBreakTime.bind(this, false, "")} >手動入力</Button>
                                                            </div>
                                                            <p></p>
                                                            <Button className="btn btn-secondary my-2" onClick={props.closeModal} >閉じる</Button>
                                                        </div>
                                                    </div>
                                                </Modal>

                                                <Modal
                                                    isOpen={props.manualBreakTimeModalIsOpen}
                                                    onRequestClose={props.closeManualBreakTimeModal}
                                                    contentLabel="Example Modal"
                                                    shouldCloseOnOverlayClick={true}
                                                    style={{
                                                        overlay: {
                                                            zIndex: 999999999
                                                        },
                                                        content: {
                                                            border: 'none',
                                                            maxWidth: '500px',
                                                            maxHeight: '400px',
                                                            margin: 'auto'
                                                        },

                                                    }}
                                                ><div>
                                                        <div className="form-group">
                                                            <p>{props.manualWorkDate}の退勤を入力しますか？(手動)</p>
                                                            <p>休憩時間に変更がある場合は入力してください</p>
                                                            <Form.Group >
                                                                <Form.Label>休憩時間(分)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="manualBreakTime"
                                                                    value={props.manualBreakTime}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Button
                                                                variant='info'
                                                                onClick={props.handleEditTime.bind(this, false, "")}
                                                                className={styles.attendanceButton}
                                                            >退勤</Button>
                                                        </div>
                                                    </div></Modal>
                                            </Col>

                                            <Col md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }} className={classNames("text-center", styles.paging)}>
                                                <div role="group" aria-label="Basic example" className="btn-group">
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 1)}>前月</Button>
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 0)}>当月</Button>
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, -1)}>次月</Button>
                                                </div>
                                            </Col>
                                            <Col md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }} className="text-right">
                                                <Button className={styles.output} onClick={props.handleOutputCsv}>CSV出力</Button>
                                            </Col>

                                            <Col md={{ span: 12 }} className={classNames("text-left", styles.mobilePaging)}>
                                                <div role="group" aria-label="Basic example" className="btn-group">
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 1)}>前月</Button>
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 0)}>当月</Button>
                                                    <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, -1)}>次月</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Table bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">日付</th>
                                                        <th scope="col">出勤時間</th>
                                                        <th scope="col">退勤時間</th>
                                                        <th scope="col">実稼働時間</th>
                                                        <th scope="col">休憩時間</th>
                                                        <th className="text-nowrap" scope="col">備考</th>
                                                        <th scope="col">削除</th>
                                                    </tr>
                                                </thead>
                                                {props.workList &&

                                                    <tbody>
                                                        {props.workList.map((work, index) =>
                                                            <tr key={index} className={classNames({
                                                                [styles.saturday]: work.week === 'saturday',
                                                                [styles.sunday]: work.week === 'sunday'
                                                            })}>
                                                                <td >
                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            <Moment
                                                                                locale="ja"
                                                                                format="YYYY/MM/DD (ddd)">
                                                                                {work.workDate}
                                                                            </Moment>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td >
                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            {work.startTime}
                                                                        </Col>
                                                                        <Col>
                                                                            {work.startTime ?
                                                                                !work.startLocationName || work.startLocationName === "手動入力" ?
                                                                                    <Button onClick={props.showLocation} disabled={true}
                                                                                        className={styles.disabledLocationButton}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                    </Button>

                                                                                    :
                                                                                    <Popup
                                                                                        trigger={
                                                                                            <Button onClick={props.showLocation} className={styles.locationButton}>
                                                                                                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                            </Button>}
                                                                                        position="top center"
                                                                                    >
                                                                                        <p className={styles.locationName}>{work.startLocationName}</p>
                                                                                        <iframe
                                                                                            title={index}
                                                                                            frameBorder="0"
                                                                                            style={{ width: "100%", height: "100%" }}
                                                                                            src={`https://maps.google.co.jp/maps?output=embed&q=${work.startLatitude},${work.startLongitude}`}>
                                                                                        </iframe>
                                                                                    </Popup>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td >
                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            {work.endTime}
                                                                        </Col>
                                                                        <Col>
                                                                            {work.endTime ?
                                                                                !work.endLocationName || work.endLocationName === "手動入力" ?
                                                                                    <Button onClick={props.showLocation} disabled={true}
                                                                                        className={styles.disabledLocationButton}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                    </Button>

                                                                                    :
                                                                                    <Popup
                                                                                        trigger={
                                                                                            <Button onClick={props.showLocation} className={styles.locationButton}>
                                                                                                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                            </Button>}
                                                                                        position="top center"
                                                                                    >
                                                                                        <p className={styles.locationName}>{work.endLocationName}</p>
                                                                                        <iframe
                                                                                            title={index}
                                                                                            frameBorder="0"
                                                                                            style={{ width: "100%", height: "100%" }}
                                                                                            src={`https://maps.google.co.jp/maps?output=embed&q=${work.endLatitude},${work.endLongitude}`}>
                                                                                        </iframe>
                                                                                    </Popup>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td >{work.actualWorkTimeStr}</td>
                                                                <td >{work.breakTimeStr}</td>
                                                                <td ><Form.Control
                                                                    as="textarea"
                                                                    name="comment"
                                                                    value={work.comment ? work.comment : ""}
                                                                    onFocus={props.handleOnFocus.bind(this, index)}
                                                                    onChange={props.handleCommentChange.bind(this, index)}
                                                                    onBlur={props.handleCommentBlur.bind(this, work.workDate)}
                                                                    disabled={!work.isCommentAvailable}
                                                                    className={styles.comment}
                                                                    maxLength={20}
                                                                /></td>
                                                                <td>

                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            <Popup
                                                                                trigger={
                                                                                    <Button variant="danger">削除</Button>
                                                                                }
                                                                                modal
                                                                                closeOnDocumentClick
                                                                                position="right center"
                                                                                contentStyle={{
                                                                                    borderRadius: "10px"
                                                                                }}
                                                                            >

                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",

                                                                                    }}
                                                                                >
                                                                                    <Row className="text-left">
                                                                                        <Col>
                                                                                            <p>
                                                                                                {work.workDate}のデータを削除します。
                                                                                                </p>
                                                                                            <p>よろしいですか？
                                                                                                    </p>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </div>
                                                                                <Row className="text-center">
                                                                                    <Col>
                                                                                        <Button className="btn btn-secondary my-2" variant="danger" onClick={props.handleDeleteWorkDate.bind(this, work.workDate, work.startTime, work.endTime)} >削除</Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Popup>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                }
                                            </Table >
                                        </Row>
                                    </div></Col>
                            </>
                        } else {
                            // mobile版
                            return <>

                                <Col md={{ span: 5, offset: 1 }} >

                                    <Row>
                                        <Col className="text-center">

                                            <Button onClick={props.openSummaryModal} className={styles.topButtons}>
                                                <FontAwesomeIcon icon={faChartPie} className={styles.icons} />
                                            </Button>
                                            <p>サマリー</p>
                                            <Popup
                                                open={props.isSummaryModalOpen}
                                                closeOnDocumentClick
                                                onClose={props.closeSummaryModal}
                                                contentStyle={{
                                                    width: "90%"
                                                }}
                                            >
                                                <Table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>稼働日数</th>
                                                            <th>稼働時間</th>
                                                            <th>時間外</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{props.workDayCount}</td>
                                                            <td>{props.totalTime}</td>
                                                            <td>{props.overTime}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Popup>
                                        </Col>
                                        <Col className="text-center">
                                            <Button onClick={props.openMonthlyCommentModal} className={styles.topButtons}>
                                                <FontAwesomeIcon icon={faPencilAlt} className={styles.icons} />
                                            </Button>
                                            <p>月次コメント</p>
                                            <Popup
                                                open={props.isMonthlyCommentModalOpen}
                                                closeOnDocumentClick
                                                onClose={props.closeMonthlyCommentModal}
                                                contentStyle={{
                                                    width: "90%"
                                                }}
                                            >

                                                <div className={styles.timeCard}>
                                                    <Row className={styles.timeCardButtons}>
                                                        {props.workList.length > 0 &&
                                                            <Col md={{ span: 12 }} >
                                                                <Form className={styles.monthlyCommentForm}>
                                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                                        {/* <Form.Label>月次コメント入力</Form.Label> */}
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="monthlyComment"
                                                                            value={props.monthlyComment}
                                                                            onChange={props.handleChange}
                                                                            maxLength={40}
                                                                        />
                                                                    </Form.Group>
                                                                    <div className="text-right">
                                                                        <Button type='sumbit' variant="primary" onClick={props.handleCreateMonthlyComment}>登録</Button>
                                                                    </div>
                                                                </Form>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </div>
                                            </Popup>
                                        </Col>
                                        <Col className="text-center">
                                            <Button className={classNames(styles.topButtons, styles.csv)} onClick={props.handleOutputCsv}>
                                                <FontAwesomeIcon icon={faFileCsv} className={styles.icons} />
                                            </Button>
                                            <p>CSV出力</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={{ span: 6 }} />
                                <Col md={{ span: 10, offset: 1 }} >
                                    <Row>
                                        <Col className="text-left">-出勤情報-</Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 11, offset: 1 }} >
                                            <div className={classNames(styles.range)}>
                                                {props.rangeInfo ?
                                                    `${props.rangeInfo.startDate} 〜 ${props.rangeInfo.endDate} までの出勤情報を表示します`
                                                    : <span>期間取得に失敗しました</span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: "10px" }}>
                                        <Col className={classNames("text-center", styles.mobilePaging)}>
                                            <div role="group" aria-label="Basic example" className="btn-group">
                                                <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 1)}>前月</Button>
                                                <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, 0)}>当月</Button>
                                                <Button className="btn btn-secondary" onClick={props.handleGetMonthlyWorkList.bind(this, -1)}>次月</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Accordion
                                                allowMultipleExpanded={true}
                                                allowZeroExpanded={true}
                                                className={classNames(styles.accordion, "edit-accordion")}
                                                onChange={props.changeAccordion}
                                            >
                                                {props.workList &&
                                                    <>
                                                        {props.workList.map((work, index) =>

                                                            <div key={index} className={classNames(styles.day, {
                                                                [styles.saturday]: work.week === 'saturday',
                                                                [styles.sunday]: work.week === 'sunday'
                                                            })}>
                                                                <Row className={styles.dayRowDate}>
                                                                    <Col>
                                                                        <Moment
                                                                            locale="ja"
                                                                            format="YYYY/MM/DD (ddd)">
                                                                            {work.workDate}
                                                                        </Moment>
                                                                    </Col>
                                                                </Row>
                                                                <Row className={styles.dayRows}>
                                                                    <Col>
                                                                        <Row style={{ margin: 0 }} className="align-items-center">
                                                                            <Col>
                                                                                <Row>
                                                                                    出勤時間:
                                                                                </Row>
                                                                                <Row>
                                                                                    {work.startTime}
                                                                                </Row>
                                                                            </Col>
                                                                            <Col>
                                                                                {work.startTime ?
                                                                                    !work.startLocationName || work.startLocationName === "手動入力" ?
                                                                                        <Button
                                                                                            onClick={props.showLocation}
                                                                                            disabled={true}

                                                                                            className={styles.disabledLocationButton}>
                                                                                            <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                        </Button>
                                                                                        :
                                                                                        <Popup
                                                                                            trigger={
                                                                                                <Button onClick={props.showLocation} className={styles.locationButton}>
                                                                                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                                </Button>}
                                                                                            position="top center"
                                                                                        >
                                                                                            <p className={styles.locationName}>{work.startLocationName}</p>
                                                                                            <iframe
                                                                                                title={index}
                                                                                                frameBorder="0"
                                                                                                style={{ width: "100%", height: "100%" }}
                                                                                                src={`https://maps.google.co.jp/maps?output=embed&q=${work.startLatitude},${work.startLongitude}`}>
                                                                                            </iframe>
                                                                                        </Popup>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col>
                                                                        <Row style={{ margin: 0 }} className="align-items-center">
                                                                            <Col>
                                                                                <Row>
                                                                                    退勤時間:
                                                                                </Row>
                                                                                <Row>
                                                                                    {work.endTime}
                                                                                </Row>
                                                                            </Col>
                                                                            <Col>
                                                                                {work.endTime ?
                                                                                    !work.endLocationName || work.endLocationName === "手動入力" ?
                                                                                        <Button onClick={props.showLocation} disabled={true}
                                                                                            className={styles.disabledLocationButton}>
                                                                                            <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                        </Button>
                                                                                        :
                                                                                        <Popup
                                                                                            trigger={
                                                                                                <Button onClick={props.showLocation} className={styles.locationButton}>
                                                                                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icons} />
                                                                                                </Button>}
                                                                                            position="top center"

                                                                                            className="end-popup"
                                                                                        >
                                                                                            <p className={styles.locationName}>{work.endLocationName}</p>
                                                                                            <iframe
                                                                                                title={index}
                                                                                                frameBorder="0"
                                                                                                style={{ width: "100%", height: "100%" }}
                                                                                                src={`https://maps.google.co.jp/maps?output=embed&q=${work.endLatitude},${work.endLongitude}`}>
                                                                                            </iframe>
                                                                                        </Popup>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className={styles.dayRows}>
                                                                    <Col>
                                                                        稼働時間: {work.actualWorkTimeStr}
                                                                    </Col>
                                                                    <Col>
                                                                        休憩時間: {work.breakTimeStr}
                                                                    </Col>
                                                                </Row>
                                                                <AccordionItem >
                                                                    <AccordionItemHeading>
                                                                        <AccordionItemButton

                                                                        >
                                                                            編集
                                                                        </AccordionItemButton>
                                                                    </AccordionItemHeading>
                                                                    <AccordionItemPanel>
                                                                        {props.editLoading ?
                                                                            <DefaultLoading {...props} />
                                                                            :
                                                                            <div>
                                                                                <div className="form-group">
                                                                                    <RadioGroup
                                                                                        name="which"
                                                                                        value={props.which}
                                                                                        onChange={props.handleRadioClick}
                                                                                        depth={3}
                                                                                    >
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <RadioButton label="出勤" value="punchIn" />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <RadioButton label="退勤" value="punchOut" />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </RadioGroup>

                                                                                    <Form.Group >
                                                                                        <Form.Label>時間</Form.Label>
                                                                                        <Form.Control
                                                                                            type="time"
                                                                                            name="manualWorkTime"
                                                                                            value={props.manualWorkTime}
                                                                                            onChange={props.handleChange}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    <div className="text-right">
                                                                                        <Button className="btn btn-secondary my-2" onClick={props.handleCalcManualBreakTime.bind(this, true, work.workDate)} >手動入力</Button>
                                                                                    </div>
                                                                                    {
                                                                                        props.isSetBreakTime &&
                                                                                        <div className={classNames("form-group", styles.breakTimeWrap)}>
                                                                                            <Form.Group>
                                                                                                <Form.Label>休憩時間(分)</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    name="manualBreakTime"
                                                                                                    value={props.manualBreakTime}
                                                                                                    onChange={props.handleChange}
                                                                                                />
                                                                                            </Form.Group>
                                                                                            <Button
                                                                                                variant='info'
                                                                                                onClick={props.handleEditTime.bind(this, true, work.workDate)}
                                                                                                className={styles.attendanceButton}
                                                                                            >退勤</Button>
                                                                                        </div>
                                                                                    }
                                                                                    <Form.Group >
                                                                                        <Form.Label>備考</Form.Label>
                                                                                        <Form.Control
                                                                                            as="textarea"
                                                                                            name="comment"
                                                                                            value={work.comment ? work.comment : ""}
                                                                                            onFocus={props.handleOnFocus.bind(this, index)}
                                                                                            onChange={props.handleCommentChange.bind(this, index)}
                                                                                            onBlur={props.handleCommentBlur.bind(this, work.workDate)}
                                                                                            disabled={!work.isCommentAvailable}
                                                                                            className={styles.comment}
                                                                                            maxLength={20}
                                                                                        />
                                                                                    </Form.Group>

                                                                                    <div style={{ textAlign: "center", marginTop: "50px" }}>
                                                                                        <Popup
                                                                                            trigger={
                                                                                                <Button variant="danger">勤務データの削除</Button>

                                                                                            }
                                                                                            modal
                                                                                            closeOnDocumentClick
                                                                                            position="right center"
                                                                                            contentStyle={{
                                                                                                width: "90%",
                                                                                                // marginLeft: "5%",
                                                                                                // marginRight: "5%",
                                                                                                borderRadius: "10px",
                                                                                            }}
                                                                                            lockScroll={true}
                                                                                        >
                                                                                            <Row className="text-left">
                                                                                                <Col>
                                                                                                    <p>
                                                                                                        {work.workDate}のデータを削除します。
                                                                                                </p>
                                                                                                    <p>よろしいですか？
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row className="text-center">
                                                                                                <Col>
                                                                                                    <Button className="btn btn-secondary my-2" variant="danger" onClick={props.handleDeleteWorkDate.bind(this, work.workDate, work.startTime, work.endTime)} >削除</Button>
                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Popup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </AccordionItemPanel>
                                                                </AccordionItem>
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        }
                    }}
                </MediaQuery>
                <Col md={{ span: 1 }}></Col>
            </Row>
        }
    </div>
);

export default TimeCard;
