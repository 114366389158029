import React, { Component } from "react";
import interceptorAxios from '../../lib/axios.js';
import Settings from "./presenter";

class Container extends Component {
    state = {
        userId: this.props.userInfo.userId,
        loading: true,
        saving: false,
        breakTimeList: [],
        closingDayList: [],
    }

    async componentDidMount() {

        window.scrollTo(0, 0);
        const _this = this;
        const userId = this.state.userId;
        await interceptorAxios.get(`/api/v1/user/settings/${userId}`)
            .then(result => {
                if (result.data) {
                    _this.setState({
                        loading: false,
                        ...result.data
                    });
                }

                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                console.log(err)

                this.setState({
                    loading: false,
                })
            });
    }

    _closingDayList = [
        { code: 5, name: " 5日" },
        { code: 10, name: "10日" },
        { code: 15, name: "15日" },
        { code: 20, name: "20日" },
        { code: 25, name: "25日" },
        { code: 0, name: "末日" },
    ];

    render() {
        return (
            <Settings
                {...this.props}
                {...this.state}
                closingDayList={this._closingDayList}
                handleChange={this._change}
                handleCheck={this._check}
                handleChangeBreakTime={this._changeBreakTime}
                handleSave={this._save}
                handleAddBreakTime={this._addBreakTime}
                handleRemoveBreakTime={this._removeBreakTime}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event;
        this.setState({
            [name]: value
        });
    };

    _check = event => {
        const { target: { name, checked } } = event;
        this.setState({
            [name]: checked
        });
    };

    _changeBreakTime = event => {
        const { target: { name, value } } = event;
        const { breakTimeList } = this.state;

        const index = event.target.getAttribute('data-index')
        breakTimeList[index][name] = value;

        this.setState(
            Object.assign({}, breakTimeList)
        );
    };

    _save = event => {
        const _this = this;
        const userId = this.state.userId;
        _this.setState({ saving: true });

        interceptorAxios.put(`/api/v1/user/settings/${userId}`, this.state)
            .then(result => {
                _this.setState({ saving: false });
                alert("保存しました。");
            })
            .catch(err => {
                console.log(err)
            });

    }

    _addBreakTime = () => {
        const { breakTimeList } = this.state;
        this.setState(
            Object.assign({}, breakTimeList, {
                breakTimeList: breakTimeList.concat({ start: "12:00", end: "13:00" })
            })
        );
    }

    _removeBreakTime = targetIndex => {
        const { breakTimeList } = this.state;

        this.setState(
            Object.assign({}, breakTimeList, {
                breakTimeList: breakTimeList.filter((element, index, array) => {
                    return targetIndex !== index;
                })
            })
        );
    }
}


export default Container;
