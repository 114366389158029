import React, { Component } from "react";
import Login from "./presenter";
import validator from 'validator';
import { toast } from 'react-toastify';

class Container extends Component {
    state = {
        userId: "",
        password: "",
        isPasswordReset: false,
        loading: false,
    }

    render() {
        return (
            <Login
                {...this.props}
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
                googleSignin={this._googleSignin}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        this.setState({ loading: true });
        event.preventDefault();
        const { challengeLogin, getUserInfo } = this.props;
        const { userId, password } = this.state;

        if (this._loginValidate(userId, password)) {
            const loginResult = await challengeLogin(userId, password);
            const getUserResult = await getUserInfo(userId);
            if (loginResult && getUserResult) this.props.history.push("/");
        };

        this.setState({ loading: false });
    }

    _googleSignin = () => {
        const { googleSignin } = this.props;
        googleSignin();
    }

    _loginValidate = (userId, password) => {
        let result = true;

        if (validator.isEmpty(userId) || validator.isEmpty(password)) {
            toast.error("ユーザーIDとパスワードを入力してください");
            result = false;
        }
        if (!validator.isEmail(userId)) {
            toast.error("ユーザーIDはEmail形式で入力してください");
            result = false;
        }
        if (!validator.isLength(userId, { max: 256 })) {
            toast.error("ユーザーIDは256文字以内でで入力してください");
            result = false;
        }

        return result;
    }
}

export default Container;
