import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import DefaultLoading from "../DefaultLoading";

const PasswordResetForm = props => (

    <main>
        {
            props.loading ?
                <DefaultLoading {...props} />
                :
                <Container className={classNames(styles.container, "mx-auto")}>
                    <Row className={classNames(styles.formWrap, "mx-auto")}>
                        <Col md={{ span: 8, offset: 2 }} className={classNames("right", "my-auto", "text-center")}>
                            <Row className={"align-self-center"}>
                                <Col className={"mx-auto"}>
                                    <Form className={styles.form}>
                                        <h4>登録パスワードのリセット</h4>

                                        {props.verifyStatus === "EXPIRED" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>送信されたメールの有効期限が切れています。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "PARAM_NG" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>入力されたパラメータが不正です。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "PARAM_OK" &&

                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <Form.Group>
                                                    <Form.Label className="login-form-label text-black-50" />Password
                                            <Form.Control
                                                        type="password"
                                                        name="password"
                                                        value={props.password}
                                                        onChange={props.handleChange}
                                                        className="form-control password"
                                                    />
                                                </Form.Group>
                                                確認のためもう一度入力してください。:
                                            <Form.Group>
                                                    <Form.Label className="login-form-label text-black-50" />Password
                                            <Form.Control
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={props.confirmPassword}
                                                        onChange={props.handleChange}
                                                        className="form-control password"
                                                    />
                                                </Form.Group>
                                                <Button className={classNames("btn", styles.loginButton, "text-white")} onClick={props.handleSubmit}>パスワード変更</Button>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
        }
    </main >
);

export default PasswordResetForm;
