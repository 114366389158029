import React, { Component } from "react";
import axios from 'axios'
import Settings from "./presenter";

class Container extends Component {
    state = {
        userId: this.props.userInfo.userId,
        loading: true,
        saving: false,
        breakTimeList: [],
        closingDayList: [],
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        userName: this.props.userInfo.userName,
    }

    async componentDidMount() {
        const _this = this;
        const userId = this.state.userId;
        await axios.get(`/api/v1/user/settings/${userId}`)
            .then(result => {
                if (result.data) {
                    _this.setState({
                        loading: false,
                        ...result.data
                    });
                }

                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                console.log(err)

                this.setState({
                    loading: false,
                })
            });
    }

    render() {
        return (
            <Settings
                {...this.props}
                {...this.state}
                handleChange={this._change}
                handleUpdatePassword={this._updatePassword}
                handleUpdateUserName={this._updateUserName}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event;
        this.setState({
            [name]: value
        });
    };

    _updatePassword = async event => {
        this.setState({ loading: true });
        event.preventDefault();
        const { changePassword } = this.props;
        const {
            userId,
            oldPassword,
            newPassword,
            newPasswordConfirm,
        } = this.state;

        if (userId === ""
            || oldPassword === ""
            || newPassword === ""
            || newPasswordConfirm === "") {
            alert('入力してください');
        } else if (newPassword !== newPasswordConfirm) {
            alert('新しいパスワードが一致していません')
        } else {
            await changePassword(userId,
                oldPassword,
                newPassword,
                newPasswordConfirm);
        }


        this.setState({ loading: false });

    }

    _updateUserName = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { userId, userName } = this.state;
        const { changeUserInfo } = this.props;
        await changeUserInfo(userId, userName);

        this.setState({ loading: false });
    }

}


export default Container;
