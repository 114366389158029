import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const {
        user:
        {
            userInfo: { userId },
            monthlyWork,
            workTimeStatusInfo,
            rangeInfo,
        },
    } = state;
    return {
        userId,
        monthlyWork,
        workTimeStatusInfo,
        rangeInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        outputCsv: (param) => {
            dispatch(userActions.outputCsv(param));
        },
        getEmployeesInfo: () => {
            return dispatch(userActions.getEmployeesInfo());
        },
        getRange: (params) => {
            return dispatch(userActions.getRange(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
