import React from "react";
import Loading from "components/Loading";
import Saving from "components/Saving";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import classNames from "classnames";

const Settings = props => (
    <div className={styles.wrapper}>
        {
            props.loading ?
                <Loading />
                :
                props.saving ?
                    <Saving />
                    :
                    <Row>
                        <Col md={{ span: 12 }} className={styles.head}>
                            <h5>プロフィール</h5>
                        </Col>
                        <Col md={{ span: 11, offset: 1 }} className={styles.block}>
                            <Form role="form" id="login" className={styles.form}
                                onSubmit={props.handleUpdatePassword}>

                                <h4>パスワード変更</h4>
                                <div className="mb-3 login-form-wrap">
                                    <Form.Group>
                                        <Form.Label className="login-form-label text-black-50" />Email
                                            <p>{props.userId}</p>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="login-form-label text-black-50" />パスワード
                                            <Form.Control
                                            type="password"
                                            name="oldPassword"
                                            value={props.oldPassword}
                                            onChange={props.handleChange}
                                            className="form-control password"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="login-form-label text-black-50" />新しいパスワード
                                            <Form.Control
                                            type="password"
                                            name="newPassword"
                                            value={props.newPassword}
                                            onChange={props.handleChange}
                                            className="form-control password"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="login-form-label text-black-50" />新しいパスワード(確認)
                                            <Form.Control
                                            type="password"
                                            name="newPasswordConfirm"
                                            value={props.newPasswordConfirm}
                                            onChange={props.handleChange}
                                            className="form-control password"
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Col lg="12">
                                            <Button type="submit" className={classNames("btn", styles.loginButton, "text-white")} >パスワード変更</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>

                        <Col md={{ span: 11, offset: 1 }} className={styles.block}>
                            <Form role="form" id="login" className={styles.form}
                                onSubmit={props.handleUpdateUserName}>
                                <h4>表示名変更</h4>
                                <div className="mb-3 login-form-wrap">
                                    <Form.Group>
                                        <Form.Label className="login-form-label text-black-50" />表示名
                                            <Form.Control
                                            type="text"
                                            name="userName"
                                            value={props.userName}
                                            onChange={props.handleChange}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Col lg="12">
                                            <Button type="submit" className={classNames("btn", styles.loginButton, "text-white")} >表示名変更</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>

                    </Row>
        }
    </div>

);

export default Settings; 
