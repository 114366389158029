import axios from 'axios';
import interceptorAxios from '../lib/axios';
import contentDisposition from 'content-disposition';
import firebase from '../lib/firebase.js';
import { saveAs } from 'file-saver';
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_RANGE = "SET_RANGE";
const SET_TIME = "SET_TIME";
const SET_LOCATION_NAME = "SET_LOCATION_NAME";
const SET_MONTHLY_WORK_LIST = "SET_MONTHLY_WORK_LIST";
const SET_WORK_TIME_STATUS_INFO = "SET_WORK_TIME_STATUS_INFO";
const SET_USER_INFO = "SET_USER_INFO";

// action creator
function login(userInfo) {
    return {
        type: LOGIN,
        userInfo
    };
}

function logout() {
    return {
        type: LOGOUT
    };
}

function setRange(rangeInfo) {
    return {
        rangeInfo,
        type: SET_RANGE
    };
}

function setMonthlyWorkList(monthlyWork) {
    return {
        monthlyWork,
        type: SET_MONTHLY_WORK_LIST,
    }
}
function setUserInfo(userInfo) {
    return {
        userInfo,
        type: SET_USER_INFO,
    }
}


// API actions

// ログイン
function challengeLogin(userId, password) {
    const crypto = require('crypto');
    const md5 = crypto.createHash('md5');
    password = md5.update(password, 'binary').digest('hex');

    const param = {
        userId,
        password
    }

    return async dispatch => {
        return await axios.post('/api/v1/user/login/', param)
            .then(async result => {
                const {
                    data: {
                        accessToken
                    }
                } = result;

                await localStorage.setItem("jwt", accessToken);
                return true;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

function getUserInfo(userId) {
    return async (dispatch, getState) => {
        return await interceptorAxios.get(`/api/v1/user/${userId}`)
            .then(result => {

                // TODO: 初回ログイン判定用
                let notLoggedInUsers = JSON.parse(localStorage.getItem('notLoggedInUsers'));
                if (notLoggedInUsers) {

                    if (notLoggedInUsers.find(item => item === userId)) {
                        result.data.initLoggedIn = true;
                        let idx = notLoggedInUsers.indexOf(userId);
                        if (idx >= 0) {
                            notLoggedInUsers.splice(idx, 1);
                        }
                        localStorage.setItem('notLoggedInUsers', JSON.stringify(notLoggedInUsers));
                    }
                }

                localStorage.setItem("authUser", userId);
                dispatch(login(result.data));
                return true;
            })
            .catch(err => {
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            alert('tokenの有効期限(1時間)が切れたため、ログアウトします')
                            dispatch(logout());
                            break;
                        default:
                            alert('ユーザー情報取得に失敗しました');
                            break;
                    }
                } else {
                    alert('ユーザー情報取得に失敗しました');
                }
                console.log(err)
            })
    }
}

// API actions
function changePassword(userId, oldPassword, newPassword, newPasswordConfirm) {
    if (newPassword !== newPasswordConfirm) {
        alert('新しいパスワードと確認用のパスワードが違います');
        return;
    }
    const crypto = require('crypto');
    const md5 = crypto.createHash('md5');
    newPassword = md5.update(newPassword, 'binary').digest('hex');

    const crypto2 = require('crypto');
    const md5_2 = crypto2.createHash('md5');
    oldPassword = md5_2.update(oldPassword, 'binary').digest('hex');

    const param = {
        userId,
        oldPassword,
        newPassword
    }

    return async dispatch => {
        return await interceptorAxios.put(`/api/v1/user/change`, param)
            .then(result => {

                alert('パスワード変更成功しました')
                console.log(result.data);
            })
            .catch(err => {
                alert('パスワード変更失敗しました')
                console.log(err)
            });
    }
}

// ユーザー登録
function registerUser(userId, userName, password, authority) {
    const crypto = require('crypto');
    const md5 = crypto.createHash('md5');
    password = md5.update(password, 'binary').digest('hex');

    const param = {
        userId,
        userName,
        password,
        authority,
    }

    return async dispatch => {
        return await axios.post(`/api/v1/user/${userId}`, param)
            .then(result => {

                //  TODO: 初回ログイン判定用
                let notLoggedInUsers = JSON.parse(localStorage.getItem('notLoggedInUsers'));
                if (notLoggedInUsers) {
                    notLoggedInUsers.push(userId);
                } else {
                    notLoggedInUsers = [userId];
                }
                localStorage.setItem('notLoggedInUsers', JSON.stringify(notLoggedInUsers));

                return true;
            })
            .catch(err => {
                alert('ユーザー登録でエラーになりました');
                console.log(err)
            });
    }
}

// ユーザー登録メール送信
function sendRegisterUserMail(userId, authority) {
    return async dispatch => {
        return await axios.post(`/api/v1/user/${userId}/verify/register`, { authority })
            .then(result => {
                console.log('send user register result =>', result);
                return result.data;
            })
            .catch(err => {
                console.log(err);
            });
    }
}

// ユーザーverify
function verifyRegisterUser(verifyParam) {
    return async dispatch => {
        return await axios.get(`/api/v1/user/verify/${verifyParam}`)
            .then(result => {
                console.log('verify user register result =>', result);

                return result.data;
            })
            .catch(err => {
                console.log(err);
                return err;
            });
    }
}

// 休憩時間計算
function calcBreakTime(userId, workDate) {
    return async dispatch => {
        return await interceptorAxios.get(`/api/v1/attendance/${userId}/breakTime/${workDate}/`)
            .then(result => {
                console.log(result);
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}


// 休憩時間計算(手動)
function calcManualBreakTime(userId, workDate, endTime) {
    return async dispatch => {
        return await interceptorAxios.get(`/api/v1/attendance/${userId}/breakTime/${workDate}/${endTime}/`)
            .then(result => {
                console.log(result);
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

// 出勤退勤入力
function setTime(params) {
    return async (dispatch, getState) => {
        const { userId } = params;
        return await interceptorAxios.post(`/api/v1/attendance/${userId}/setTime/`, params)
            .then(result => {
                console.log("set time result", result);
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

// 出勤退勤入力(手動)
function editTime(params) {
    return async dispatch => {
        const { userId } = params;
        return await interceptorAxios.post(`/api/v1/attendance/${userId}/manualSetTime`, params)
            .then(result => {
                console.log("set manual time result", result);
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

// 出勤退勤状態チェック
function checkWorkTimeStatus(userId) {
    return async dispatch => {
        return await interceptorAxios.get(`/api/v1/attendance/${userId}/status`)
            .then(result => {
                console.log("check worktime status result", result);
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

// 月次作業一覧
function getMonthlyWorkList(param) {
    return async dispatch => {
        const {
            userId,
            month
        } = param;

        return await interceptorAxios.get(`/api/v1/attendance/${userId}/lists/${month}`)
            .then(result => {
                const monthlyWork = {
                    workList: result.data.workTimeList,
                    totalTime: result.data.totalTime,
                    overTime: result.data.overTime,
                    workDayCount: result.data.workDayCount,
                    monthlyComment: result.data.monthlyComment,
                }
                dispatch(setMonthlyWorkList(monthlyWork));
            })
            .catch(err => {
                alert('月次作業一覧取得でエラーになりました');
                console.log(err)
            });
    }
}

// 月次期間取得
function getRange(params) {
    return async (dispatch, getState) => {
        const { month } = params;
        const { user: { userInfo: { userId } } } = getState();
        return await interceptorAxios.get(`/api/v1/user/${userId}/range/${month}`)
            .then(result => {
                const rangeInfo = {
                    startDate: result.data.range.startDate,
                    endDate: result.data.range.endDate,
                }
                dispatch(setRange(rangeInfo));
            })
            .catch(err => {

                alert('月次期間取得でエラーになりました');
                console.log(err)
            });
    }
}

// 月次コメント取得
function getMonthlyComment(userId, month) {
    return async dispatch => {
        return await interceptorAxios.get(`/api/v1/attendance/${userId}/comment/${month}`)
            .then(result => {
                return result.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}

// 月次コメント登録
function createMonthlyComment(userId, month, monthlyComment) {
    return async dispatch => {
        const params = { userId, month, monthlyComment };
        return await interceptorAxios.post(`/api/v1/attendance/${userId}/comment/${month}`, params)
            .then(result => {
                console.log(`create monthly comment result => ${result}`);
                return result.data;
            })
            .catch(err => {
                console.log(err);
            });
    }
}

// csv出力
function outputCsv(param) {
    return dispatch => {
        const {
            userId,
            month
        } = param;

        return interceptorAxios.get(`/api/v1/attendance/${userId}/csv/${month}`)
            .then(result => {
                console.log('output csv result =>', result);
                const contentDispositionHeader = result.headers["content-disposition"];
                const params = contentDisposition.parse(contentDispositionHeader);

                // bom付きでないとmobileで見た時に文字化けしてしまう。
                const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                const blob = new Blob([bom, result.data], { type: "text/csv;charset=utf-8" });
                saveAs(blob, params.parameters.filename);
            })
            .catch(err => {
                alert('CSV出力でエラーになりました');
                console.log(err);
            });
    }
}

// TODO:
// googleログイン
function googleSignin() {
    return dispatch => {
        const provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().signInWithPopup(provider).then(function (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const token = result.credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(token);
            console.log(user);
            dispatch(login(user));

        }).catch(function (error) {
            console.log(error);
        });
    }
}

// パスワードリセットメール送信
function sendPasswordReset(userId) {
    return dispatch => {
        return axios.post(`/api/v1/user/${userId}/verify/reset`, { authority: "user" })
            .then(result => {
                console.log('send password-reset result =>', result);

                return result.data;
            })
            .catch(err => {
                console.log(err);
                return false;
            });
    }
}

// パスワードリセットverify
function passwordResetVerify(verifyParam) {
    return async dispatch => {
        return await axios.get(`/api/v1/user/verify/${verifyParam}`)
            .then(result => {
                console.log('send password-reset result =>', result);

                return result.data;
            })
            .catch(err => {
                console.log(err);
                return false;
            });
    }
}

// パスワードリセット
function passwordReset(password, verifyParam) {
    return async dispatch => {
        const crypto = require('crypto');
        const md5 = crypto.createHash('md5');
        password = md5.update(password, 'binary').digest('hex');

        const param = {
            password,
            verifyParam
        }
        return await axios.post(`/api/v1/user/reset/change`, param)
            .then(result => {
                console.log('send password-reset result =>', result);

                return true;
            })
            .catch(err => {
                console.log(err);
                return false;
            });
    }
}

// 備考コメント更新
function updateComment(userId, workDate, comment) {
    return async dispatch => {
        const param = {
            comment,
        }
        return await interceptorAxios.post(`/api/v1/attendance/${userId}/workComment/${workDate}`, param)
            .then(result => {
                console.log(`update comment result => ${result}`);
                return result.data;
            })
            .catch(err => {
                console.log(err);
            });
    }
}


// ユーザー情報変更
function changeUserInfo(userId, userName) {
    const param = {
        userId,
        userName,
    }

    return async dispatch => {
        return await interceptorAxios.put(`/api/v1/user/${userId}`, param)
            .then(result => {

                alert('表示名変更に成功しました')
                console.log(result.data);
                dispatch(setUserInfo(result.data));
            })
            .catch(err => {
                alert('表示名変更に失敗しました')
                console.log(err)
            });
    }
}


// 従業員一覧取得
function getEmployeesInfo() {
    return async (dispatch, getState) => {
        return await interceptorAxios.get(`/api/v1/user/list`)
            .then(result => {
                return result.data;
            })
            .catch(err => {
                alert('従業員情報取得に失敗しました');
                console.log(err)
            })
    }
}

// 勤怠情報削除
function deleteWorkDate(userId, workDate) {
    return async () => {
        return await interceptorAxios.delete(`/api/v1/attendance/${userId}/workTime/${workDate}`)
            .then(result => {
                console.log(`delete work date result => ${result}`);
                return result.data;
            })
            .catch(err => {

                console.log(err)
                return err.response;
            })
    }
}

// 初期state
const initialState = () => {
    return {
        userInfo: {},
        isLoggedIn: localStorage.getItem("jwt") && localStorage.getItem("authUser") ? true : false,
        token: localStorage.getItem("jwt"),
    }
};

// reducer
function reducer(state = initialState(), action) {
    switch (action.type) {
        case LOGIN:
            return onLogin(state, action);
        case LOGOUT:
            return onLogout(state, action);
        case SET_RANGE:
            return onRange(state, action);
        case SET_TIME:
            return onTime(state, action);
        case SET_LOCATION_NAME:
            return onLocationName(state, action);
        case SET_MONTHLY_WORK_LIST:
            return onMonthWorkList(state, action);
        case SET_WORK_TIME_STATUS_INFO:
            return onWorkTimeStatus(state, action);
        case SET_USER_INFO:
            return onUserInfo(state, action);
        default:
            return state;
    }
}

// reducer functions
function onLogin(state, action) {
    return {
        isLoggedIn: true,
        userInfo: action.userInfo
    };
}

function onLogout(state, action) {
    console.log('ログアウト処理')
    localStorage.removeItem("jwt");
    localStorage.removeItem('authUser');
    return initialState();
}

function onRange(state, action) {
    return {
        ...state,
        rangeInfo: action.rangeInfo
    };
}

function onTime(state, action) {
    return {
        ...state,
        timeInfo: action.timeInfo
    };
}

function onLocationName(state, action) {
    return {
        ...state,
        locationName: action.locationName
    };
}

function onMonthWorkList(state, action) {
    return {
        ...state,
        monthlyWork: action.monthlyWork
    };
}

function onWorkTimeStatus(state, action) {
    return {
        ...state,
        workTimeStatusInfo: action.workTimeStatusInfo
    };
}

function onUserInfo(state, action) {
    return {
        ...state,
        userInfo: action.userInfo
    };
}

// exports
const actionCreators = {
    challengeLogin,
    registerUser,
    logout,
    getRange,
    setTime,
    getMonthlyWorkList,
    editTime,
    outputCsv,
    checkWorkTimeStatus,
    changePassword,
    getMonthlyComment,
    createMonthlyComment,
    googleSignin,
    getUserInfo,
    calcBreakTime,
    calcManualBreakTime,
    sendPasswordReset,
    passwordResetVerify,
    passwordReset,
    sendRegisterUserMail,
    verifyRegisterUser,
    updateComment,
    changeUserInfo,
    getEmployeesInfo,
    deleteWorkDate,
};

export { actionCreators };

export default reducer;
