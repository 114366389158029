import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import DefaultLoading from "../DefaultLoading";

const PasswordReset = props => (
    <main>
        <Container className={classNames(styles.container, "mx-auto")}>
            <Row className={classNames(styles.formWrap, "mx-auto")}>
                <Col md={{ span: 8, offset: 2 }} className={classNames("right", "my-auto", "text-center")}>
                    <Row className={"align-self-center"}>
                        <Col className={"mx-auto"}>
                            <Form className={styles.form}>
                                <h4>登録パスワードのリセット</h4>
                                {
                                    props.loading ?
                                        <DefaultLoading {...props} />
                                        :
                                        <>
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <Form.Group>
                                                    メールアドレス: <Form.Control
                                                        type="email"
                                                        name="email"
                                                        value={props.email}
                                                        onChange={props.handleChange}
                                                        placeHolder="登録時のメールアドレス"
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    確認のためもう一度入力してください。:
                                                <Form.Control
                                                        type="confirmEmail"
                                                        name="confirmEmail"
                                                        value={props.confirmEmail}
                                                        onChange={props.handleChange}
                                                    />
                                                </Form.Group>
                                                <Button className={classNames("btn", styles.loginButton, "text-white")} onClick={props.handleSubmit}>メール送信</Button>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                }
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </main >
);

export default PasswordReset;
