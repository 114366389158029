import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const {
        user:
        {
            userInfo: { userId },
            monthlyWork,
            workTimeStatusInfo,
            rangeInfo,
        },
    } = state;
    return {
        userId,
        monthlyWork,
        workTimeStatusInfo,
        rangeInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRange: (params) => {
            return dispatch(userActions.getRange(params));
        },
        getMonthlyWorkList: (month) => {
            return dispatch(userActions.getMonthlyWorkList(month));
        },
        getMonthlyComment: (userId, month) => {
            return dispatch(userActions.getMonthlyComment(userId, month));
        },
        createMonthlyComment: (userId, month, monthlyComment) => {
            return dispatch(userActions.createMonthlyComment(userId, month, monthlyComment));
        },
        editTime: (params) => {
            return dispatch(userActions.editTime(params));
        },
        outputCsv: (param) => {
            dispatch(userActions.outputCsv(param));
        },
        calcManualBreakTime: (userId, workDate, endTime) => {
            return dispatch(userActions.calcManualBreakTime(userId, workDate, endTime));
        },
        updateComment: (userId, workDate, comment) => {
            return dispatch(userActions.updateComment(userId, workDate, comment));
        },
        deleteWorkDate: (userId, workDate) => {
            return dispatch(userActions.deleteWorkDate(userId, workDate));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
