import { connect } from "react-redux";
import Container from "./container";

const mapStateToProps = state => {
    const { user:
        { userInfo: { userId, initLoggedIn, roles = [] } }
    } = state;
    return {
        userId,
        initLoggedIn,
        roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
