import React from "react";
import Loading from "components/Loading";
import Saving from "components/Saving";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Settings = props => (
    <div className={styles.wrapper}>
        {
            props.loading ?
                <Loading />
                :
                props.saving ?
                    <Saving />
                    :
                    <Row>
                        <Col md={{ span: 12 }} className={styles.head}>
                            <h5>設定</h5>
                        </Col>
                        <Col md={{ span: 4, offset: 1 }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>締め日</Form.Label>
                                <Form.Control as="select"
                                    name="closingDay"
                                    value={props.closingDay}
                                    onChange={props.handleChange}>
                                    {props.closingDayList.map(item => (
                                        <option key={item.code} value={item.code}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 11, offset: 1 }}>
                            <h6>休日</h6>
                        </Col>
                        <Col md={{ span: 11, offset: 1 }}>

                            <input
                                type="checkbox"
                                name="holidayFlgSun"
                                value="true"
                                checked={props.holidayFlgSun}
                                onChange={props.handleCheck}
                            />日曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgMon"
                                value="true"
                                checked={props.holidayFlgMon}
                                onChange={props.handleCheck}
                            />月曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgTue"
                                value="true"
                                checked={props.holidayFlgTue}
                                onChange={props.handleCheck}
                            />火曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgWed"
                                value="true"
                                checked={props.holidayFlgWed}
                                onChange={props.handleCheck}
                            />水曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgThu"
                                value="true"
                                checked={props.holidayFlgThu}
                                onChange={props.handleCheck}
                            />木曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgFri"
                                value="true"
                                checked={props.holidayFlgFri}
                                onChange={props.handleCheck}
                            />金曜日<br />
                            <input
                                type="checkbox"
                                name="holidayFlgSat"
                                value="true"
                                checked={props.holidayFlgSat}
                                onChange={props.handleCheck}
                            />土曜日<br />
                        </Col>
                        <Col md={{ span: 11, offset: 1 }} className={styles.attendanceTime}>
                            <h6> 勤務時間:</h6>
                        </Col>
                        <Col md={{ span: 2, offset: 1 }}>
                            <Form.Group >
                                <Form.Label>始業時間</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="defaultWorkStartTime"
                                    value={props.defaultWorkStartTime}
                                    onChange={props.handleChange}
                                    className="form-control datetimepicker-input"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 2 }}>
                            <Form.Group >
                                <Form.Label>終業時間</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="defaultWorkEndTime"
                                    value={props.defaultWorkEndTime}
                                    onChange={props.handleChange}
                                    className="form-control datetimepicker-input"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 11, offset: 1 }}>
                            休憩時間:
                            <strong>（※3つまで設定可）</strong>
                        </Col>
                        <Col md={{ span: 11, offset: 1 }}>
                            <ul>
                                {props.breakTimeList.map((breakTime, targetIndex, array) => (
                                    <li key={targetIndex + 1}>
                                        <span>設定{targetIndex + 1} : </span>
                                        <input
                                            type="time"
                                            name="start"
                                            value={breakTime.start}
                                            data-index={targetIndex}
                                            onChange={props.handleChangeBreakTime}
                                            className="form-control datetimepicker-input"
                                        /> 〜
                <input
                                            type="time"
                                            name="end"
                                            value={breakTime.end}
                                            data-index={targetIndex}
                                            onChange={props.handleChangeBreakTime}
                                            className="form-control datetimepicker-input"
                                        />
                                        <Button onClick={props.handleRemoveBreakTime.bind(this, targetIndex)} className={styles.button}>削除</Button>
                                        <br />
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col md={{ span: 2, offset: 2 }}>
                            <Button onClick={props.handleAddBreakTime} className={styles.button}>追加</Button>
                        </Col>
                        <Col md={{ span: 2 }}>

                            <Button onClick={props.handleSave} className={styles.button}>保存</Button>
                        </Col>
                    </Row>
        }
    </div>

);

export default Settings; 
