import React from "react";
import classNames from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import DefaultLoading from "../DefaultLoading";

const Login = props => (
    <main>
        <Container className={classNames(styles.container)}>
            <Row className={classNames(styles.formWrap)}>

                <Col md={{ span: 8, offset: 2 }} className={classNames("right", "my-auto", "text-center")}>
                    <Row className={"align-self-center"}>
                        <Col className={"mx-auto"}>
                            <div>
                                <Form role="form" id="login" className={styles.form}
                                    onSubmit={props.handleSubmit}>

                                    <h4>ログイン</h4>
                                    {props.loading ?
                                        <DefaultLoading {...props} />
                                        :
                                        <>
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <Form.Group>
                                                    <Form.Label className="login-form-label text-black-50" />Email
                                                    <Form.Control
                                                        type="text"
                                                        name="userId"
                                                        value={props.userId}
                                                        onChange={props.handleChange}
                                                        className="form-control email"
                                                        placeholder="登録時のメールアドレス"
                                                        autoFocus="autofocus"
                                                        maxLength={256}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="login-form-label text-black-50" />Password
                                                <Form.Control
                                                        type="password"
                                                        name="password"
                                                        value={props.password}
                                                        onChange={props.handleChange}
                                                        className="form-control password"
                                                    />
                                                </Form.Group>
                                                <Row class="forget-password">
                                                    <Col sm="6" className="text-left">
                                                        {/* TODO: */}
                                                        {/* <Form.Group className="remember-me">
                                                        <Form.Check type="checkbox" name="remember-me" id="remember-me"
                                                            label="ログイン状態を保存する" />
                                                    </Form.Group> */}
                                                    </Col>
                                                    <Col lg="6" className="text-right">
                                                        パスワードを忘れた場合は<Link to="/password-reset">こちら</Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Button type="submit" className={classNames("btn", styles.loginButton, "text-white")} >ログイン</Button>
                                                    </Col>
                                                </Row>
                                                {/* TODO: ソーシャルログイン. */}
                                                {/* <Row>
                                                <Col sm="12">
                                                    <Button className="fab fa-google" onClick={props.googleSignin}> Sign in with Google</Button>
                                                </Col>
                                            </Row> */}
                                                <Row>
                                                    <Col lg="12" className="text-center">アカウントを持っていませんか？
                                                        <Link to="/register">アカウントを作成</Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </main >
);

export default Login;
