import React, { Component } from "react";
import Sidebar from "./presenter";

class Container extends Component {

    render() {
        return (
            <Sidebar
                {...this.props}
                {...this.state}
                onSelect={this._onSelect}
                onSelectForMobile={this._onSelectForMobile}
            />
        );
    }

    _onSelect = selected => {
        const to = '/' + selected;
        this.props.history.push(to);
    }

    _onSelectForMobile = selected => {
        const to = '/' + selected;
        this.props.history.push(to);
        this.props.onToggle(true, false);
    }

    componentDidMount() {
    }
}

export default Container;
