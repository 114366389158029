import React, { Component } from "react";
import Saving from "./presenter";

class Container extends Component {
    render() {
        return (
            <Saving />
        );
    }
}

export default Container;
