import React, { Component } from "react";
import Register from "./presenter";
import validator from 'validator';
import { toast } from 'react-toastify';

class Container extends Component {
    state = {
        userId: "",
        registUserName: "",
        registPassword: "",
        passwordConfirm: "",
        loading: false,
    }

    render() {
        return (
            <Register
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { sendRegisterUserMail } = this.props;
        const { userId = "", confirmUserId = "" } = this.state;

        if (validator.isEmpty(userId) || validator.isEmpty(confirmUserId)) {
            toast.error('入力してください');
        } else if (userId !== confirmUserId) {
            toast.error("メールアドレスが一致していません。");
        } else if (!validator.isLength(userId, { max: 256 })
            || !validator.isLength(confirmUserId, { max: 256 })) {
            toast.error("メールアドレスは256文字以内で入力してください");
        } else {
            // 仮登録メール送る
            const result = await sendRegisterUserMail(userId, "user");
            if (result) {
                switch (result.status) {
                    case "EXIST":
                        toast.error('既にユーザーが存在しています');
                        break;
                    case "ALREADY_SENT":
                        toast.error('既に仮メール送信済みです');
                        break;
                    case "PARAM_NG":
                        toast.error("ユーザー仮登録メール送信に失敗しました");
                        break;
                    case "VERIFY_START":
                        toast.success("ユーザー仮登録メールを送信しました");
                        break;
                    default:
                        break;
                }
            }
        }

        this.setState({ loading: false });
    }
}

export default Container;
