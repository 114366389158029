import React, { Component } from "react";
import Register from "./presenter";

class Container extends Component {
    state = {
        userId: "",
        registUserName: "",
        registPassword: "",
        passwordConfirm: "",
        loading: false,
    }

    render() {
        return (
            <Register
                {...this.props}
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { sendRegisterUserMail } = this.props;
        const { userId, confirmUserId } = this.state;

        if (userId === "" || confirmUserId === "") {
            alert('入力してください');
        } else if (userId !== confirmUserId) {
            alert("メールアドレスが一致していません。");
        } else {
            // 仮登録メール送る
            const result = await sendRegisterUserMail(userId, "admin");
            if (result) {

                switch (result.status) {
                    case "EXIST":
                        alert('既にユーザーが存在しています');
                        break;
                    case "ALREADY_SENT":
                        alert('既に仮メール送信済みです');
                        break;
                    case "PARAM_NG":
                        alert("ユーザー仮登録メール送信に失敗しました")
                        break;
                    case "VERIFY_START":
                        alert("ユーザー仮登録メールを送信しました")
                        break;
                    default:
                        break;
                }
            } else {
                alert("ユーザー仮登録メール送信に失敗しました")
            }
        }

        this.setState({ loading: false });
    }
}

export default Container;
