import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const { user: { isLoggedIn, userInfo } } = state;
    return {
        isLoggedIn,
        userInfo
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getUserInfo: (userId) => {
            return dispatch(userActions.getUserInfo(userId));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
