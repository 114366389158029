import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const { user: { isLoggedIn, userInfo } } = state;
    return {
        isLoggedIn,
        userInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (userId, oldPassword, newPassword, newPasswordConfirm) => {
            return dispatch(userActions.changePassword(userId, oldPassword, newPassword, newPasswordConfirm));
        },
        changeUserInfo: (userId, userName) => {
            return dispatch(userActions.changeUserInfo(userId, userName));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
