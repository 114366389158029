import React, { Component } from "react";
import TimeCard from "./presenter";
import { toast } from 'react-toastify';
import validator from 'validator';
import moment from 'moment';

class Container extends Component {

    state = {
        month: 0,
        workDayCount: 0,
        totalTime: null,
        overTime: null,
        workList: [],
        comment: "",
        monthlyComment: "",
        showEdit: false,
        manualWorkDate: "",
        manualWorkTime: "",
        manualComment: "",
        which: false,
        manualBreakTime: 60,
        manualDoUpdate: false,
        csvStartDate: "",
        csvEndDate: "",
        loading: true,
        manualBreakTimeModalIsOpen: false,
        oldComment: "",

        isSetBreakTime: false,
        editLoading: false,
        isTopContentsOpen: false,
    }

    render() {
        return (
            <TimeCard
                {...this.props}
                {...this.state}
                handleChange={this._handleChange}
                handleOnFocus={this._handleOnFocus}
                handleCommentChange={this._handleCommentChange}
                handleCommentBlur={this._handleCommentBlur}
                handleGetMonthlyWorkList={this._getMonthlyWorkList}
                handleEditTime={this._handleEditTime}
                handleOutputCsv={this._handleOutputCsv}
                handleRadioClick={this._handleRadioClick}
                handleCreateMonthlyComment={this._createMonthlyComment}
                openModal={this._openModal}
                closeModal={this._closeModal}
                handleCalcManualBreakTime={this._handleCalcManualBreakTime}
                openManualBreakTimeModal={this._openManualBreakTimeModal}
                closeManualBreakTimeModal={this._closeManualBreakTimeModal}
                changeAccordion={this._changeAccordion}
                showLocation={this._showLocation}
                openSummaryModal={this._openSummaryModal}
                closeSummaryModal={this._closeSummaryModal}
                openMonthlyCommentModal={this._openMonthlyCommentModal}
                closeMonthlyCommentModal={this._closeMonthlyCommentModal}
                handleDeleteWorkDate={this._deleteWorkDate}
            />
        );
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        const { month, activeStartDate } = this.state;
        // 期間の取得
        await this._getMonthlyWorkList(month);

        this.setState({
            activeStartDate
        })
        this.setState({ loading: false, })
    }

    _handleChange = event => {
        const { target: { name, value } } = event
        this.setState({
            [name]: value
        });
    }

    _handleCommentChange = (index, event) => {
        const { target: { value } } = event
        const { workList } = this.state;

        if (workList) {
            // console.log(`changedComment : ${value}`);
            // console.log(`oldComment : ${this.state.oldComment}`);
            workList[index].comment = value;
            this.setState({ workList });
        }
    }

    _handleOnFocus = (index, event) => {
        console.log("onFocus");
        const { workList } = this.state;
        if (workList) {
            // console.log(`comment : ${workList[index].comment}`);
            this.setState({
                oldComment: workList[index].comment != null ? workList[index].comment : ""
            })
        }

    }

    _handleCommentBlur = (workDate, event) => {
        // console.log("備考コメントblur");
        const { target: { value } } = event
        const { userId, updateComment } = this.props;

        if (this.state.oldComment !== value) {
            if (!validator.isLength(value, { min: 0, max: 20 })) {
                toast.error("コメントは20文字以内で入力してください");
            } else {
                // コメント更新
                updateComment(userId, workDate, value);
            }
        }
    }

    _handleRadioClick = (value, event) => {
        // const { target: { name, value } } = event
        // if (name === `which`) {
        console.log(value);
        switch (value) {
            case "punchIn":
                if (this.state.isSetBreakTime) this.setState({ isSetBreakTime: false });
                break;
            default:
                break;
        }
        // }
        this.setState({
            which: value
        });
    }

    _getMonthlyWorkList = async monthCoefficient => {
        const { getMonthlyWorkList } = this.props;
        const { userId } = this.props;
        let { month } = this.state;
        if (monthCoefficient !== 0) {
            month = month + (monthCoefficient);
        } else if (monthCoefficient === 0) {
            month = 0;
        }
        const param = {
            userId,
            month
        }
        await getMonthlyWorkList(param);
        const { monthlyWork } = this.props;
        if (monthlyWork) {
            this.setState({
                workList: monthlyWork.workList,
                workDayCount: monthlyWork.workDayCount,
                totalTime: monthlyWork.totalTime,
                overTime: monthlyWork.overTime,
                csvStartDate:
                    monthlyWork.workList &&
                        monthlyWork.workList.length > 0 ?
                        monthlyWork.workList[0].workDate
                        :
                        "",
                csvEndDate:
                    monthlyWork.workList &&
                        monthlyWork.workList.length > 0 ?
                        monthlyWork.workList[monthlyWork.workList.length - 1].workDate
                        :
                        "",
            });

            const workDate = monthlyWork.workList[0].workDate;
            await this._getMonthyComment(workDate.replace(/-/g, "").substring(0, 6));
        };
        this.setState({
            month
        })

        await this._getRange(month);
    }

    // 月次期間取得
    _getRange = async month => {
        const { getRange, userId } = this.props;
        await getRange({ userId, month });
        const { rangeInfo } = this.props;
        this.setState({
            rangeInfo,
        })
    }

    // 月次コメント取得
    _getMonthyComment = async month => {
        const { getMonthlyComment, userId } = this.props;
        let { monthlyComment } = this.state;

        let isError = false;
        if (validator.isEmpty(userId)) {
            toast.error(`月次期間取得に失敗しました。ユーザーIDを入力してください`);
            isError = true;
        }
        if (!moment(month).isValid()) {
            toast.error(`月次期間取得に失敗しました。対象月を正しい日付形式で入力してください`);
            isError = true;
        }

        if (!isError) {
            const result = await getMonthlyComment(userId, month);
            if (result) {
                monthlyComment = result.monthlyComment;
            } else {
                monthlyComment = "";
            }
            this.setState({
                monthlyComment
            })
        }
    }

    // 月次コメント登録
    _createMonthlyComment = async event => {
        this.setState({ loading: true, })
        const { createMonthlyComment, userId } = this.props;
        const { monthlyComment, workList } = this.state;
        const workDate = workList[0].workDate;
        const workStr = workDate.replace(/-/g, "").substring(0, 6)
        console.log(`月次コメント登録: ${workStr}`);

        // validation
        let isError = false;
        if (validator.isEmpty(userId)) {
            toast.error(`ユーザーIDを入力してください`);
            isError = true;
        }
        if (validator.isEmail(workStr)) {
            toast.error(`月を指定してください`);
            isError = true;
        }
        if (!moment(workStr).isValid()) {
            toast.error(`対象月: 正しい日付形式で入力してください`);
            isError = true;
        }
        if (!validator.isLength(monthlyComment, { max: 40 })) {
            toast.error(`月次コメント: 40文字以内で入力してください`);
            isError = true;
        }
        if (!isError) {
            const result = await createMonthlyComment(userId, workStr, monthlyComment);
            if (result) {
                toast.success(`月次コメント登録に成功しました`);
            }
            this._getMonthyComment(workStr);
        } else {
            event.preventDefault();
        }
        this.setState({ loading: false, })
    }

    // 休憩時間計算(手動)
    _handleCalcManualBreakTime = async (fromMobile, workDate) => {
        if (fromMobile) {
            this.setState({ editLoading: true });
        } else {
            this.setState({
                loading: true,
            })
        }
        const { manualWorkDate, manualWorkTime, which } = this.state;

        if (which === "punchIn") {
            if (fromMobile) {
                this.setState({ manualWorkDate: workDate }, async () => {
                    await this._handleEditTime(fromMobile, workDate);
                });
            } else {
                await this._handleEditTime()
            }
        } else if (which === "punchOut") {
            const { userId, calcManualBreakTime } = this.props;
            const result = await calcManualBreakTime(userId, manualWorkDate || workDate, manualWorkTime);

            if (result) {
                const breakTime = result.breakTime
                this.setState({ manualBreakTime: breakTime })
                this._openManualBreakTimeModal();
                this._closeModal();
                console.log("休憩時間計算成功(手動)");
                if (fromMobile) this.setState({ isSetBreakTime: true, });
            } else {
                alert("休憩時間計算(手動)でエラーになりました。出勤時間が入力されているか、確認してください");
                this.setState({
                    manualWorkDate: "",
                    manualWorkTime: "",
                });
                if (fromMobile) this.setState({ isSetBreakTime: false });
            }

            this._closeModal();
        }
        if (!fromMobile) {
            this.setState({ loading: false, })
        } else {
            this.setState({ editLoading: false });
        }
    }

    _handleEditTime = async (fromMobile, manualWorkDateFromMobile) => {

        if (fromMobile) {
            this.setState({ editLoading: true });
        } else {
            this.setState({
                loading: true,
            })
        }
        const { editTime, userId } = this.props;
        const {
            manualWorkDate,
            manualWorkTime,
            manualComment,
            manualBreakTime,
            which,
        } = this.state;

        const params = {
            userId,
            manualDate: manualWorkDate,
            manualTime: manualWorkTime,
            which: which === "punchIn" ? true : false,
            breakTime: manualBreakTime,
            comment: manualComment,
            doUpdate: false
        };

        if (fromMobile) params.manualDate = manualWorkDateFromMobile;

        const result = await editTime(params);
        if (result) {
            if (result.data.status === "ENDTIME") {
                alert('既に入力されています')
            } else {
                alert(result.message);
            }
        } else {
            alert("手動出退勤入力で失敗しました");
        }
        const { month } = this.state;

        // 期間再度取得
        await this._getMonthlyWorkList(month);
        this._closeModal();
        this._closeManualBreakTimeModal();
        this.setState({
            manualWorkDate: "",
            manualWorkTime: "",
            isSetBreakTime: false,
        })
        if (fromMobile) {
            this.setState({ editLoading: false });
        } else {
            this.setState({
                loading: false,
            })
        }
    }

    _handleOutputCsv = event => {
        const { outputCsv, userId } = this.props;
        const { month } = this.state;
        const param = {
            userId,
            month
        }
        outputCsv(param);
    }



    _openModal = () => {
        this.setState({ modalIsOpen: true });
    }
    _closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
    _openManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: true });
    }
    _closeManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: false });
    }
    _selectCalendarDay = (date) => {
        this.setState({
            calendarModalIsOpen: !this.state.calendarModalIsOpen,
            calendarDay: date[0].day(),
        });
    }

    _openCalendarModal = () => {
        this.setState({ calendarModalIsOpen: true });
    }
    _closeCalendarModal = () => {
        this.setState({ calendarModalIsOpen: false });
    }

    _changeAccordion = (key) => {
        console.log(key);
        this.setState({ isSetBreakTime: false });
    }

    _showLocation = () => {

    }

    _openSummaryModal = () => {
        this.setState({ isSummaryModalOpen: true, isMonthlyCommentModalOpen: false });
    }
    _closeSummaryModal = () => {
        this.setState({ isSummaryModalOpen: false, isMonthlyCommentModalOpen: false });
    }

    _openMonthlyCommentModal = () => {
        this.setState({ isSummaryModalOpen: false, isMonthlyCommentModalOpen: true });
    }
    _closeMonthlyCommentModal = () => {
        this.setState({ isSummaryModalOpen: false, isMonthlyCommentModalOpen: false });
    }

    _deleteWorkDate = async (workDate, startTime, endTime) => {
        this.setState({ loading: true });
        const { deleteWorkDate, userId, } = this.props;
        const { month } = this.state;

        // validation
        if ((startTime === null && endTime === null)
            ||
            (validator.isEmpty(startTime) && validator.isEmpty(endTime))) {
            toast.error("出勤情報が登録されていないか削除済みです。");
        } else {
            // request
            const result = await deleteWorkDate(userId, workDate);
            console.log(result);
            if (result.code === "OK") {
                toast.success(result.message);
                // データ取り直し
                await this._getMonthlyWorkList(month);
            } else if (result.data.code === "NOT_FOUND") {
                toast.error(result.data.message);
            }

        }

        this.setState({ loading: false });
    }

}

export default Container;
