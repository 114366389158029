import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCog, faHistory, faFileCsv, faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import MediaQuery from "react-responsive";

const Sidebar = props => (
    <MediaQuery maxDeviceWidth={480}>
        {matches => {
            if (matches) {
                return <>
                    {/* mobile側 */}
                    <SideNav
                        expanded={props.mobileExpanded}
                        onSelect={
                            props.onSelectForMobile
                        }
                        className={classNames(styles.sidebar,
                            { [styles.mobileSidebarToggle]: props.mobileExpanded }
                        )}
                        onToggle={props.onToggle}
                        mobileFlg={true}
                    >
                        <SideNav.Toggle
                            componentClass={() => {
                                return (
                                    <React.Fragment >
                                        <div className={styles.togglerWrap}>
                                            <button className={styles.toggler} onClick={props.onToggle.bind(this, props.expanded, !props.mobileExpanded)}>
                                                <FontAwesomeIcon icon={faBars} className={styles.icons} />
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )
                            }}

                        />
                        <SideNav.Nav defaultSelected="attendance">
                            <NavItem eventKey="attendance">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faPen} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/attendance">
                                        ホーム</Link>
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="timecard">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faHistory} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/timecard"
                                    >
                                        タイムカード</Link>
                                </NavText>
                            </NavItem>

                            <NavItem eventKey="settings">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faCog} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/settings"
                                    >
                                        設定 </Link>
                                </NavText>
                            </NavItem>


                            {props.roles.includes("r_admin") &&
                                <>
                                    <div className={classNames(styles.admin,
                                    )}>
                                        <p>管理者設定</p>
                                    </div>
                                </>
                            }
                            {props.roles.includes("r_admin") &&

                                <NavItem eventKey="employee-settings">
                                    <NavIcon>
                                        <FontAwesomeIcon icon={faFileCsv} className={styles.icons} />
                                    </NavIcon>
                                    <NavText>
                                        <Link to="/employee-settings">
                                            従業員CSV出力</Link>
                                    </NavText>
                                </NavItem>

                            }
                        </SideNav.Nav>
                    </SideNav>

                </>
            } else {
                return <>
                    {/* desktop側 */}
                    <SideNav
                        expanded={props.expanded}
                        onSelect={
                            props.onSelect
                        }
                        className={classNames(styles.sidebar)}
                        onToggle={props.onToggle}
                    >
                        <SideNav.Toggle
                            componentClass={() => {
                                return (
                                    <React.Fragment >
                                        <div className={styles.togglerWrap}>
                                            <button className={styles.toggler} onClick={props.onToggle.bind(this, !props.expanded, props.mobileExpanded)}>
                                                <FontAwesomeIcon icon={faBars} className={styles.icons} />
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )
                            }}

                        />
                        <SideNav.Nav defaultSelected="attendance">
                            <NavItem eventKey="attendance">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faPen} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/attendance">
                                        ホーム</Link>
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="timecard">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faHistory} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/timecard"
                                    >
                                        タイムカード</Link>
                                </NavText>
                            </NavItem>

                            <NavItem eventKey="settings">
                                <NavIcon>
                                    <FontAwesomeIcon icon={faCog} className={styles.icons} />
                                </NavIcon>
                                <NavText>
                                    <Link to="/settings"
                                    >
                                        設定 </Link>
                                </NavText>
                            </NavItem>


                            {props.roles.includes("r_admin") &&
                                <>
                                    <div className={classNames(styles.admin,
                                        { [styles.adminExpanded]: !props.expanded }
                                    )}>
                                        <p>管理者設定</p>
                                    </div>
                                </>
                            }
                            {props.roles.includes("r_admin") &&

                                <NavItem eventKey="employee-settings">
                                    <NavIcon>
                                        <FontAwesomeIcon icon={faFileCsv} className={styles.icons} />
                                    </NavIcon>
                                    <NavText>
                                        <Link to="/employee-settings">
                                            従業員CSV出力</Link>
                                    </NavText>
                                </NavItem>

                            }
                        </SideNav.Nav>
                    </SideNav>

                </>
            }
        }}
    </MediaQuery>
);

export default Sidebar;
