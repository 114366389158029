import React, { Component } from "react";
import DashboardHeader from "./presenter";

class Container extends Component {

    state = {
        showDropdown: false
    }

    render() {
        return (
            <DashboardHeader
                {...this.state}
                {...this.props}
                handleLogout={this._logout}
                closeNav={this._closeNav}
                navMenuClick={this._navMenuClick}
            />
        );
    }

    _logout = event => {
        event.preventDefault();
        const { logout } = this.props;
        logout();
    }


    _navMenuClick = () => {
        this.setState({ showDropdown: !this.state.showDropdown });
    }
}

export default Container;
