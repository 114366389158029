import React, { Component } from "react";
import PasswordReset from "./presenter";

class Container extends Component {
    state = {
        email: "",
        confirmUserId: "",
        loading: false,
    }

    render() {
        return (
            <PasswordReset
                {...this.props}
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
            />
        );
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        this.setState({ loading: true });
        const { email, confirmEmail } = this.state;
        const { sendPasswordReset } = this.props;

        if (email === "" || confirmEmail === "") {
            alert('メールアドレスを入力してください');
        } else if (email !== confirmEmail) {
            alert('メールアドレスが一致していません');
        } else {
            const result = await sendPasswordReset(email);
            if (result) {
                switch (result.status) {
                    case "ALREADY_SENT":
                        alert('既にパスワードリセットメール送信済みです');
                        break;
                    case "PARAM_NG":
                        alert('パスワードリセットメール送信処理に失敗しました');
                        break;
                    case "VERIFY_START":
                        alert('パスワードリセットメールを送信しました');
                        break;
                    default:
                        break;
                }
            } else {
                alert('パスワードリセットメール送信でエラーになりました');
            }
        }

        this.setState({ loading: false });
    }
}

export default Container;
