import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

const TopHeader = props => (

    <Navbar
        // collapseOnSelect
        expand="md"
        fixed="top"
        className={classNames(styles.customNavbar)}>
        <Container>
            <Navbar.Brand href="/" className={classNames(styles.brand, "navbar-brand")}>
                {/* <img src={`${process.env.PUBLIC_URL}/pittankun2.svg`} className={styles.pittan} alt="pittan" /> */}
                <img src={`${process.env.PUBLIC_URL}/logo.svg`} className={styles.logo} alt="logo" />
                <span style={{
                    letterSpacing: "0px",
                    fontSize: "12px",
                }}>(α版)</span>
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className={styles.navbarToggler} /> */}
            <Navbar id="responsive-navbar-nav" className={styles.responsiveNavbar}>
                <Nav className="mr-auto">
                    {/* <Nav.Link href="#features">Features</Nav.Link>
                    <Nav.Link href="#pricing">Pricing</Nav.Link>
                    <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
                <Nav>
                    <Link to="/login">
                        <Button variant="outline-primary" className={styles.login}>ログイン</Button>
                    </Link>
                </Nav>
            </Navbar>

        </Container>
    </Navbar>
);

export default TopHeader;





