import React, { Component } from "react";
import App from "./presenter";

class Container extends Component {
    state = {
        expanded: true,
        mobileExpanded: false,
    }
    render() {
        return (
            <App
                {...this.state}
                {...this.props}
                onToggle={this._onToggele}
            />
        );
    }

    componentDidMount = async () => {
        const authUser = localStorage.getItem("authUser");
        const { getUserInfo, isLoggedIn } = this.props;
        if (authUser && isLoggedIn) {
            await getUserInfo(authUser);
        } else {
            localStorage.removeItem("authUser");
            console.log('認証済みユーザー情報がありません');
        }
    }

    _onToggele = (expanded, mobileExpanded) => {
        if (mobileExpanded) {
            document.body.setAttribute('style', 'overflow: hidden;');
        } else {
            document.body.removeAttribute('style', 'overflow: hidden;');
        }
        this.setState({ expanded, mobileExpanded });
    }
}

export default Container;
