import React, { Component } from "react";
import Attendance from "./presenter";

class Container extends Component {

    state = {
        date: new Date(),
        time: new Date(),
        isStart: true,
        locationStatus: 0,
        locationId: 0,
        geoOptions: {
            enableHighAccuracy: true,
            maximumAge: Infinity,
            timeout: 5000
        },
        latitude: 0,
        longitude: 0,
        workTimeStatus: {
            status: null,
            date: null
        },
        breakTime: 0,
        workStatus: "",
        workDate: "",
        loading: true,
        comment: "",
        manualWorkTime: "",
        manualComment: "",
        which: false,
        modalIsOpen: false,
        modalIsClose: false,
        breakTimeModalIsOpen: false,
        breakTimeModalIsClose: false,
    }

    render() {
        return (
            <Attendance
                {...this.props}
                {...this.state}
                toggleTimeCard={this._toggleTimeCard}
                handleSetTime={this._handleSetTime}
                toggleSidebar={this._toggleSidebar}
                handleChange={this._change}
                openErrorModal={this._openErrorModal}
                closeErrorModal={this._closeErrorModal}
                handleEditTime={this._handleEditTime}
                handleSetTimeConfirm={this._handleSetTimeConfirm}
                openBreakTimeModal={this._openBreakTimeModal}
                closeBreakTimeModal={this._closeBreakTimeModal}
                handleCalcBreakTime={this._handleCalcBreakTime}
                handleCalcManualBreakTime={this._handleCalcManualBreakTime}
                openManualBreakTimeModal={this._openManualBreakTimeModal}
                closeManualBreakTimeModal={this._closeManualBreakTimeModal}
            />
        );
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.interval = setInterval(this.tick, 1);
        // ステータス確認
        await this._checkWorkTimeStatus();
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        const now = new Date();
        const date = now.getFullYear()
            .toString() + "/" + (now.getMonth() + 1)
                .toString() + "/" + now.getDate().toString()
            + [" (Sun)", " (Mon)", " (Tue)", " (Wed)", " (Thu)", " (Fri)", " (Sat)"][now.getDay().toString()];
        const time = now.toLocaleTimeString();

        this.setState({
            date,
            time,
        })

        // 位置情報取得
        this._getGeoLocation();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    tick = () => {
        this.setState({ time: new Date().toLocaleString() });
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _getGeoLocation = async () => {
        if (navigator.geolocation) {
            const locationId = await this._watchPosition();
            this.setState({
                locationId
            })
        } else {
            console.log('Geolocation is not supported for this Browser/OS.');
        }
    }

    _watchPosition = async () => {
        const {
            geoOptions,
        } = this.state;

        return await navigator.geolocation.watchPosition(async position => {
            console.log(position);
            const { locationId } = this.state;
            const locData = position.coords;
            this.setState({
                latitude: locData.latitude,
                longitude: locData.longitude,
                locationStatus: 1,
            })

            console.log("location id === " + locationId);
            setTimeout(() => {
                navigator.geolocation.clearWatch(locationId);
                console.log("clear location_" + locationId);
            }, 5000);

        }, error => {
            console.log("error occurred. error code:" + error.code);

            this.setState({
                locationStatus: 2,
                geoLocationErrCode: error.code,
            })
            if (error.code === 3) {
                setTimeout(() => {
                    console.log("retry getLocation");
                    this._getGeoLocation();
                }, 3000);
            }

            // error.code can be:
            //   0: unknown error
            //   1: permission denied
            //   2: position unavailable (error response from location provider)
            //   3: timed out

        }, geoOptions);
    }

    _toggleTimeCard = () => {
        this.setState({
            showTimeCard: !this.state.showTimeCard
        })
    }

    // 休憩時間計算
    _handleCalcBreakTime = async () => {
        this.setState({ loading: true })
        await this._checkWorkTimeStatus();
        const { workDate } = this.state;
        const { userId, calcBreakTime } = this.props;
        const result = await calcBreakTime(userId, workDate);

        if (result) {
            const breakTime = result.breakTime;
            this.setState({ breakTime });
            console.log("休憩時間計算成功");
        } else {
            alert("休憩時間計算でエラーになりました");
        }

        this.setState({ loading: false })
        this._openBreakTimeModal();
    }

    // 休憩時間計算(手動)
    _handleCalcManualBreakTime = async () => {
        this.setState({ loading: true })
        // ステータスチェック
        await this._checkWorkTimeStatus();
        let { workDate, manualWorkTime } = this.state;
        const { userId, calcManualBreakTime } = this.props;
        const result = await calcManualBreakTime(userId, workDate, manualWorkTime);

        if (result) {
            const breakTime = result.breakTime
            this.setState({ breakTime })
            this._openManualBreakTimeModal();
            this._closeErrorModal();
            console.log("休憩時間計算成功(手動)");
        } else {
            alert("休憩時間計算(手動)でエラーになりました");
            this.setState({ manualWorkTime: "" });
        }

        this._closeErrorModal();
        this.setState({ loading: false })
    }

    // 出退勤
    _handleSetTime = async () => {
        this.setState({ loading: true })

        // 位置情報再取得
        await this._getGeoLocation();

        const {
            latitude,
            longitude,
            breakTime,
            workDate,
            // workStatus
        } = this.state;
        const { setTime, userId } = this.props;
        const params = {
            latitude,
            longitude,
            breakTime,
            userId,
            workDate,
        }

        // 打刻
        const result = await setTime(params);
        if (result) {
            if (result.data.status === "ENDTIME") {
                alert('既に入力されています')
            } else {
                alert(result.message);
            }
        } else {
            alert("出退勤入力で失敗しました")
        }

        // ステータス取得
        await this._checkWorkTimeStatus();
        this._closeBreakTimeModal();
        this.setState({ loading: false })
    }

    // 退勤エラー時退勤
    _handleEditTime = async event => {
        this.setState({ loading: true });
        const { editTime, userId, } = this.props;
        const {
            workDate,
            manualWorkTime,
            manualComment,
            breakTime,
            // workStatus
        } = this.state;

        const params = {
            userId,
            manualDate: workDate,
            manualTime: manualWorkTime,
            which: false,
            breakTime,
            comment: manualComment,
            doUpdate: true
        };

        const result = await editTime(params);
        if (result) {
            if (result.data.status === "ENDTIME") {
                alert('既に入力されています')
            } else {
                alert(result.message);
            }
        } else {
            alert("手動出退勤入力で失敗しました");
        }

        // ステータスチェック
        await this._checkWorkTimeStatus();
        this._closeManualBreakTimeModal();
        this.setState({ loading: false })

    }

    // ステータス✔
    _checkWorkTimeStatus = async () => {
        const { checkWorkTimeStatus, userId } = this.props;
        const result = await checkWorkTimeStatus(userId);

        if (result) {
            this.setState({
                workStatus: result.status,
                workDate: result.date
            })
        } else {
            alert('勤怠ステータスチェックでエラーになりました');
        }
    }

    _openErrorModal = () => {
        this.setState({ modalIsOpen: true });
    }
    _closeErrorModal = () => {
        this.setState({ modalIsOpen: false });
    }

    _openBreakTimeModal = () => {
        this.setState({ breakTimeModalIsOpen: true });
    }
    _closeBreakTimeModal = () => {
        this.setState({ breakTimeModalIsOpen: false });
    }

    _openManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: true });
    }
    _closeManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: false });
    }

}

export default Container;
