import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import styles from "./styles.scss";

const Loading = props => (
    <div className={styles.fluidBox}>
        <LoadingOverlay
            active={true}
            spinner
            text='Loading ...'
            className={styles.loading}
        >

        </LoadingOverlay>
    </div>
);

export default Loading;
