import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const { user: { isLoggedIn, userInfo } } = state;
    return {
        isLoggedIn,
        userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(userActions.logout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
