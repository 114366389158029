import React from "react";
import styles from "./styles.scss";

const Footer = props => (
    <div className={styles.footerWrap}>
        {/* Footer */}
        <footer className="page-footer font-small cyan darken-3 text-center">
            {/* Footer Elements */}
            <div className="container">
                {/* Grid row*/}
                <div className="row">
                    {/* Grid column */}
                    <div className="col-md-12 py-5">
                        <div className="mb-5 flex-center">
                            <span>ピッたん</span>
                        </div>
                    </div>
                    {/* Grid column */}
                </div>
                {/* Grid row*/}
            </div>
            {/* Footer Elements */}
            {/* Copyright */}
            <div className="footer-copyright text-center py-3">© 2019 Copyright:
          <a href="https://www.roce.co.jp/"> 株式会社ローチェ</a>
            </div>
            {/* Copyright */}
        </footer>
        {/* Footer */}
    </div>
);

export default Footer;
