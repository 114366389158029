import React, { Component } from "react";
import EmployeSettings from "./presenter";

class Container extends Component {

    state = {
        month: 0,
        employeeList: [],
    }

    render() {
        return (
            <EmployeSettings
                {...this.props}
                {...this.state}
                handleChange={this._handleChange}
                handleCheckboxChange={this._handleChecboxChange}
                handleOutputCsv={this._handleOutputCsv}
                handleGetRange={this._getRange}
                openModal={this._openModal}
                closeModal={this._closeModal}
                openManualBreakTimeModal={this._openManualBreakTimeModal}
                closeManualBreakTimeModal={this._closeManualBreakTimeModal}
                allCheck={this._allCheck}
            />
        );
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        // 従業員取得
        this._getEmployeesInfo();
        this.setState({
            loading: false,
        })
    }

    _handleChange = event => {
        const { target: { name, value } } = event
        this.setState({
            [name]: value
        });
    }

    _handleChecboxChange = (index, event) => {
        const { target: { checked } } = event;
        const { employeeList } = this.state;

        if (employeeList) {
            employeeList[index].isCsvOutput = checked;
        }

        this.setState({
            employeeList,
        });
    }

    _getEmployeesInfo = async () => {
        const {
            getEmployeesInfo,
            // userId
        } = this.props;
        const result = await getEmployeesInfo();

        if (result) {
            const date = new Date();

            // 指定月出力のためにいろいろ生やしておく
            const employeeList = result.map(employee => {
                employee.addMonth = 0;
                employee.date = date;
                employee.month = 0;
                return employee
            })

            // TODO: 自分の分弾くか？
            // .filter(employee => {
            //     // 自分の分は弾く
            //     return employee.userId !== userId;
            // });

            console.log(employeeList);

            this.setState({
                employeeList
            });
        };
    }

    _getRange = async (userId, addMonth, index, event) => {
        const { employeeList } = this.state;

        switch (addMonth) {
            case 1:
                // 前月(APIは前月は1)
                employeeList[index].addMonth += -1;
                employeeList[index].month += 1;
                break;
            case -1:
                // 次月(APIは次月は-1)
                employeeList[index].addMonth += 1;
                employeeList[index].month += -1;
                break;
            case 0:
                employeeList[index].addMonth = 0;
                employeeList[index].month = 0;
                break;
            default:
                break;
        }

        this.setState({ employeeList });
    }

    _handleOutputCsv = userId => {
        const { outputCsv } = this.props;
        const { employeeList } = this.state;

        const checkdEmployeeList = employeeList.filter((employee) => {
            return employee.isCsvOutput;
        })

        if (!checkdEmployeeList.length) {
            alert("出力対象の従業員にチェックをしてください")
        } else {
            for (const employee of checkdEmployeeList) {
                outputCsv({ userId: employee.userId, month: employee.month });
            }
        }

    }

    _openModal = () => {
        this.setState({ modalIsOpen: true });
    }
    _closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
    _openManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: true });
    }
    _closeManualBreakTimeModal = () => {
        this.setState({ manualBreakTimeModalIsOpen: false });
    }

    _allCheck = event => {
        const { target: { checked } } = event;
        const { employeeList } = this.state;
        if (checked) {
            for (const employee of employeeList) {
                employee.isCsvOutput = true;
            }
        } else {
            for (const employee of employeeList) {
                employee.isCsvOutput = false;
            }
        }

        this.setState({ employeeList });
    }

}

export default Container;
