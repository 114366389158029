import React, { Component } from "react";
import Dashboard from "./presenter";

class Container extends Component {
    state = {
        initLoggedIn: false,
    }
    render() {
        return (
            <Dashboard
                {...this.props}
                {...this.state}
                openModal={this._openModal}
                closeModal={this._closeModal}
            />
        );
    }

    componentDidMount() {
        const { initLoggedIn } = this.props;
        this.setState({
            initLoggedIn,
        })
    }

    _openModal = () => {
        this.setState({ modalIsOpen: true });
    }
    _closeModal = () => {
        if (this.state.initLoggedIn) {
            this.props.history.push('/settings');
        }
        this.setState({
            modalIsOpen: false,
            initLoggedIn: false,
        });

    }

}

export default Container;
