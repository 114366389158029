import React from "react";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./styles.scss";
import Table from "react-bootstrap/Table";
import Loading from "components/Loading";
import Moment from 'react-moment';

const EmployeSettings = props => (

    <div className={styles.wrapper}>
        {props.loading ?
            <Loading {...props} />
            :
            <Row className={styles.timecardRow}>
                <Col md={{ span: 12 }} xs={{ span: 12 }} className={styles.head}>
                    <h5 >従業員CSV出力</h5>
                </Col>
                <Col md={{ span: 10, offset: 1 }} >
                    <div className={styles.timeCard}>
                        <Row className={styles.timeCardButtons}>
                            <Col>
                                <Button className={styles.output} onClick={props.handleOutputCsv.bind(this, props.outputUserId)}>CSV出力</Button>
                            </Col>
                        </Row>

                        <Row className={styles.timeCardButtons}>
                            <Col>
                                <Form.Group>
                                    <Form.Check type="checkbox" onChange={props.allCheck} label="すべてチェック" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th scope="col">CSV出力可否</th>
                                        <th scope="col">従業員名</th>
                                        <th scope="col">出力月選択</th>
                                        {/* <th className="text-nowrap" scope="col">備考</th> */}
                                    </tr>
                                </thead>
                                {props.employeeList &&

                                    <tbody>
                                        {props.employeeList.map((employee, index) =>
                                            <tr key={index} >
                                                <td>
                                                    <Form.Check
                                                        onChange={props.handleCheckboxChange.bind(this, index)}
                                                        checked={employee.isCsvOutput ? true : false}
                                                    ></Form.Check>
                                                </td>
                                                <td ><p>{employee.userName}</p></td>
                                                <td >
                                                    <div>
                                                        <Moment format="YYYY/MM" add={{ month: employee.addMonth }}>
                                                            {employee.date}
                                                        </Moment>
                                                    </div>
                                                    <div role="group" aria-label="Basic example" className="btn-group">
                                                        <Button className="btn btn-secondary" onClick={props.handleGetRange.bind(this, employee.userId, 1, index)}>前月</Button>
                                                        <Button className="btn btn-secondary" onClick={props.handleGetRange.bind(this, employee.userId, 0, index)}>当月</Button>
                                                        <Button className="btn btn-secondary" onClick={props.handleGetRange.bind(this, employee.userId, -1, index)}>次月</Button>
                                                    </div>
                                                    {/* ※当月のみ */}
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                }
                            </Table >
                        </Row>
                    </div>
                </Col>
                <Col md={{ span: 1 }}></Col>
            </Row>
        }
    </div>
);

export default EmployeSettings;
