import React from "react";
import classNames from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.scss";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Home = props => (
    <main>
        <Container className={classNames(styles.container)}>
            <Row className={classNames(styles.main, "text-center")}>
                <Col md={{ span: 3, offset: 1 }} xs={{ span: 12 }} className={classNames(styles.pittanWrap, "text-right")}>
                    <img src={`${process.env.PUBLIC_URL}/pittan.svg`} className={styles.pittan} alt="pittan" />
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 12 }} className={classNames(styles.brand, "text-left")}>
                    <h1>お手軽な勤怠をその手に</h1>
                </Col>
            </Row>
        </Container>
        <div className={styles.descWrap} >
            <Container >
                <Row >
                    <Col md={{ span: 12 }} className={classNames("text-center")} id='about'>
                        <p className="text-gray">
                            ピッたん勤怠とは、あなたの勤怠状況を記録、見える化して管理できるアプリです
                    </p>
                        <p className="text-center">
                            リアルタイムで位置情報を取得して記録するため、どこで打刻したかも一目瞭然
                   </p>
                        <p className="text-center">
                            締め日、休日の設定などあなたの働き方に合わせてカスタマイズ可能です
                    </p>

                    </Col>
                    <Col md={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center", "mb-3")}>
                        <Link to="/register"><Button className={styles.signup}>今すぐ無料でお試ししてみる</Button>
                        </Link>
                    </Col>

                    <Col md={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center", "mb-3", styles.news)}>
                        <p className={"text-left"}>※現在α ver.運用中です。そのため、ユーザー登録は行っておりません。ご了承ください。</p>
                        <p>サービス本運用開始までお待ち下さい！</p>
                    </Col>

                    <div className={styles.head}>
                        {/* <Container>
                            <Row> */}
                        <Col md={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center", "mb-3")}>
                            <h2>ピったん勤怠とは？</h2>
                        </Col>
                        {/* </Row>
                        </Container> */}
                    </div>
                    {/* 打刻 */}
                    <Col md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center")}>
                        <Button
                            className={classNames({
                                [styles.iconButton]: !props.attendance,
                                [styles.iconButtonActive]: props.attendance
                            })}
                            onClick={props.switchSS.bind(this, "attendance", true)}
                        >
                            <img src={`${process.env.PUBLIC_URL}/attendance.svg`} className={styles.descIcons} alt="" />
                            <p>かんたん打刻</p>
                        </Button>
                        {/* mobile用 */}
                        <button
                            className={styles.mobileButton}
                        >
                            <img src={`${process.env.PUBLIC_URL}/attendance.svg`} className={styles.descIcons} alt="" />
                            <p>かんたん打刻</p>
                        </button>
                    </Col>

                    <Col md={{ span: 12 }} className={classNames("text-center", styles.mobileSS)}>
                        <img src={`${process.env.PUBLIC_URL}/mobile_attendance_ss.png`} alt="" />
                    </Col>

                    {/* タイムカード */}
                    <Col md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center")}>
                        <Button
                            className={classNames({
                                [styles.iconButton]: !props.timecard,
                                [styles.iconButtonActive]: props.timecard
                            })}
                            onClick={props.switchSS.bind(this, "timecard", true)}
                        >
                            <img src={`${process.env.PUBLIC_URL}/watch.svg`} className={styles.descIcons} alt="" />
                            <p>勤怠を記録して見える化！</p>
                        </Button>
                        {/* mobile用 */}
                        <button
                            className={styles.mobileButton}
                        >
                            <img src={`${process.env.PUBLIC_URL}/watch.svg`} className={styles.descIcons} alt="" />
                            <p>勤怠を記録して見える化！</p>
                        </button>
                    </Col>

                    <Col md={{ span: 12 }} className={classNames("text-center", styles.mobileSS)}>
                        <img src={`${process.env.PUBLIC_URL}/mobile_timecard_ss.png`} alt="" />
                    </Col>

                    {/* 設定 */}
                    <Col md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }} className={classNames("text-center")}>
                        <Button
                            className={classNames({
                                [styles.iconButton]: !props.settings,
                                [styles.iconButtonActive]: props.settings
                            })}
                            onClick={props.switchSS.bind(this, "settings", true)}>
                            <img src={`${process.env.PUBLIC_URL}/book.svg`} className={styles.descIcons} alt="" />
                            <p>設定も自由自在</p>
                        </Button>
                        <button

                            className={styles.mobileButton}
                        >
                            <img src={`${process.env.PUBLIC_URL}/book.svg`} className={styles.descIcons} alt="" />
                            <p>設定も自由自在</p>
                        </button>
                    </Col>

                    <Col md={{ span: 12 }} className={classNames("text-center", styles.mobileSS)}>
                        <img src={`${process.env.PUBLIC_URL}/mobile_settings_ss.png`} alt="" />
                    </Col>

                    {/* desktop用ss表示領域 */}
                    {props.attendance &&
                        <Col md={{ span: 12 }} className={classNames("text-center")}>
                            <img src={`${process.env.PUBLIC_URL}/attendance_ss.png`} className={styles.ss} alt="" />
                        </Col>
                    }

                    {props.timecard &&

                        <Col md={{ span: 12 }} className={classNames("text-center")}>
                            <img src={`${process.env.PUBLIC_URL}/timecard_ss.png`} className={styles.ss} alt="" />
                        </Col>
                    }
                    {props.settings &&

                        <Col md={{ span: 12 }} className={classNames("text-center")}>
                            <img src={`${process.env.PUBLIC_URL}/settings_ss.png`} className={styles.ss} alt="" />
                        </Col>
                    }
                </Row>

            </Container>
        </div >
    </main >

);

export default Home;
