import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendPasswordReset: (userId) => {
            return dispatch(userActions.sendPasswordReset(userId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
