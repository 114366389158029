import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    const { user:
        { userInfo: { userId, }, timeInfo, workTimeStatusInfo }

    } = state;
    return {
        userId,
        timeInfo,
        workTimeStatusInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTime: (paramas, buttonType) => {
            return dispatch(userActions.setTime(paramas, buttonType));
        },
        getLocationName: (latitude, longtitude) => {
            dispatch(userActions.getLocationName(latitude, longtitude));
        },
        checkWorkTimeStatus: (userId) => {
            return dispatch(userActions.checkWorkTimeStatus(userId));
        },
        editTime: (params) => {
            return dispatch(userActions.editTime(params));
        },
        calcBreakTime: (userId, workDate) => {
            return dispatch(userActions.calcBreakTime(userId, workDate));
        },
        calcManualBreakTime: (userId, workDate, endTime) => {
            return dispatch(userActions.calcManualBreakTime(userId, workDate, endTime));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
