import React from "react";
import Container from "react-bootstrap/Container";
import styles from "./styles.scss";
import 'moment-timezone';
import 'moment/locale/ja';
import Settings from "components/Settings";
import Sidebar from "components/Sidebar";
import Attendance from "components/Attendance";
import TimeCard from "components/TimeCard";
import { Route, Switch } from "react-router-dom";
import Profile from "components/Profile";
import Modal from 'react-modal';
import EmployeeSettings from "components/EmployeeSettings";
import styled from 'styled-components';
import { generateMedia } from "styled-media-query";
import classNames from "classnames";
import MediaQuery from "react-responsive";

const customMedia = generateMedia({
    mobile: "480px",
    tablet: "740px",
    desktop: "980px",
    wide: "1300px",
});

const Main = styled.main`
    position: relative;
    transition: all .15s;
    ${customMedia.greaterThan("tablet")`
    margin-left: ${props => (props.expanded ? 240 : 64)}px;`};

    ${customMedia.lessThan("tablet")`
    margin-left: ${props => (props.expanded ? 240 : 64)}px;`};
    ${customMedia.lessThan("mobile")`
    margin-left: ${props => (props.mobileExpanded ? 0 : 0)}px;`};
`;

const Dashboard = props => (
    <Container className={styles.dashboardContainer}>
        {props.initLoggedIn &&
            <Modal
                isOpen={true}
                onRequestClose={props.closeModal}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={true}
                style={{
                    overlay: {
                        zIndex: 999999999
                    },
                    content: {
                        border: 'none',
                        maxWidth: '600px',
                        maxHeight: '400px',
                        margin: 'auto'
                    },

                }}
            >
                <h1>ようこそピッたん勤怠へ！</h1>
                <p>ホーム画面の「出勤・退勤」ボタンを使って、勤怠記録を打刻することができます</p>
                <p>タイムカード画面にてこれまでの勤怠記録を閲覧することが可能です</p>
                <p>まず最初に、設定画面から勤務形態を登録しましょう！</p>

                <p></p>
                <button className="btn btn-secondary my-2" onClick={props.closeModal} >閉じる</button>
            </Modal>
        }
        {props.userId &&
            <>
                <Switch>
                    <Route render={() => (
                        <React.Fragment>
                            {/* side-barペイン */}
                            <Sidebar {...props} />
                            {/* メインペイン */}

                            <MediaQuery query="(max-width: 480px)">

                                {props.mobileExpanded &&
                                    <div className={styles.overlay}
                                        onClick={props.onToggle.bind(this, props.expanded, !props.mobileExpanded)}
                                    ></div>
                                }

                            </MediaQuery>
                            <Main expanded={props.expanded} mobileExpanded={props.mobileExpanded}

                                className={classNames(
                                    { [styles.disabledClick]: props.mobileExpanded }
                                )}
                            >
                                <ContentsRoutes {...props} />
                            </Main>
                        </React.Fragment>
                    )}
                    />
                </Switch>
            </>
        }
    </Container>
);

const ContentsRoutes = props => (
    <React.Fragment>
        <Route exact path="/" component={Attendance} />
        <Route path="/index" component={Attendance} />
        <Route path="/attendance" component={Attendance} />
        <Route path="/settings" component={Settings} />
        <Route path="/timecard" component={TimeCard} />
        <Route path="/profile" component={Profile} />
        <Route path="/employee-settings" component={EmployeeSettings} />
    </React.Fragment>
);

export default Dashboard;
