import axios from 'axios';
import { toast } from 'react-toastify';

const interceptorAxios = axios.create();
interceptorAxios.interceptors.request.use(config => {
    console.log('exec interceptor');
    const token = window.localStorage.getItem("jwt");
    if (token != null) {
        config.headers['Authorization-Sonare'] = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

// Add a response interceptor
interceptorAxios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    const {
        data,
        status,
    } = error.response;

    // validation error alert
    if (status === 400) {
        for (const key of Object.keys(data.message)) {
            const messages = data.message[key];
            toast.error(`${key}: ${messages}`);
        }
    }
    if (status === 500) toast.error('システムエラーが発生しました');

    // Do something with response error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    const {
        data,
        status,
    } = error.response;

    console.log(error.response);
    // validation error alert
    if (status === 400) {
        for (const key of Object.keys(data.message)) {
            const messages = data.message[key];
            toast.error(`${key}: ${messages}`);
        }
    }
    if (status === 500) toast.error('システムエラーが発生しました');

    // Do something with response error
    return Promise.reject(error);
});

export default interceptorAxios;