import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        registerUser: (userId, userName, password, auhority) => {
            return dispatch(userActions.registerUser(userId, userName, password, auhority));
        },
        verifyRegisterUser: (verifyParam) => {
            return dispatch(userActions.verifyRegisterUser(verifyParam));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
