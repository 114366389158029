import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import DefaultLoading from "components/DefaultLoading";

const RegisterForm = props => (
    <main>
        <Container className={classNames(styles.container)}>
            <Row className={classNames(styles.formWrap)}>
                <Col md={{ span: 8, offset: 2 }} className={classNames("right", "my-auto", "text-center")}>
                    <Row className={"align-self-center"}>
                        <Col className={"mx-auto"}>
                            <Form className={styles.form}>
                                <h4>新規登録(管理者)</h4>
                                {props.loading ?
                                    <DefaultLoading {...props} />
                                    :
                                    <>
                                        {props.verifyStatus === "EXPIRED" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>送信されたメールの有効期限が切れています。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "PARAM_NG" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>入力されたパラメータが不正です。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "EXIST" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>入力したUserIdは既に存在しています。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "ALREADY_SENT" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                <p>入力したUserIdは既に送信済みです。</p>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                        {props.verifyStatus === "PARAM_OK" &&
                                            <div className={classNames("mb-3", styles.formGroupWrap)}>
                                                ユーザーID(メールアドレス):
                                                <div>
                                                    {props.userId}
                                                </div>
                                                名前:<Form.Control
                                                    type="text"
                                                    name="userName"
                                                    value={props.userName}
                                                    onChange={props.handleChange}
                                                    // TODO: なぜかoffが効かないので以下で対応
                                                    autoComplete="new-password"
                                                />
                                                パスワード:<Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={props.password}
                                                    onChange={props.handleChange}
                                                    // TODO: なぜかoffが効かないので以下で対応
                                                    autoComplete="new-password"
                                                />
                                                パスワード（確認）:
                                                <Form.Control
                                                    type="password"
                                                    name="passwordConfirm"
                                                    value={props.passwordConfirm}
                                                    onChange={props.handleChange}
                                                />
                                                <Button type="submit" className={classNames("btn", styles.loginButton, "text-white")} onClick={props.handleSubmit}>登録</Button>
                                                <Row>
                                                    <Col md={{ span: 12 }} className="text-center">
                                                        アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </>
                                }
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </main >
);

export default RegisterForm;
