import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import DefaultLoading from "components/DefaultLoading";

const Register = props => (
    <main>
        <Container className={classNames(styles.container)}>
            <Row className={classNames(styles.formWrap)}>
                <Col md={{ span: 8, offset: 2 }} className={classNames("right", "my-auto", "text-center")}>
                    <Row className={"align-self-center"}>
                        <Col className={"mx-auto"}>
                            <Form className={styles.form}>
                                <h4>新規登録(管理者用)</h4>
                                {props.loading ?
                                    <DefaultLoading {...props} />
                                    :
                                    <>
                                        <div className={classNames("mb-3", styles.formGroupWrap)}>
                                            ユーザーID(メールアドレス):<Form.Control
                                                type="email"
                                                name="userId"
                                                value={props.userId}
                                                onChange={props.handleChange}
                                            />
                                            ユーザーID(メールアドレス) 確認用:<Form.Control
                                                type="email"
                                                name="confirmUserId"
                                                value={props.confirmUserId}
                                                onChange={props.handleChange}
                                            />
                                            <Button type="submit" className={classNames("btn", styles.loginButton, "text-white")} onClick={props.handleSubmit}>仮登録メール送信</Button>
                                            <Row>
                                                <Col md={{ span: 12 }} className="text-center">
                                                    アカウントをお持ちの場合は<Link to="/login">こちら</Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={{ span: 12 }} className="text-center">
                                                    一般の方は<Link to="/register">こちら</Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                }
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </main >
);

export default Register;
