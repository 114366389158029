import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.scss";
import Button from "react-bootstrap/Button";
import Moment from 'react-moment';
import Form from "react-bootstrap/Form";
import Loading from "components/Loading";
import Modal from 'react-modal';

const Attendance = props => (
    <div className={styles.wrapper}>
        {props.loading ?
            <Loading {...props} />
            :
            <Row>
                <Col md={{ span: 12 }} xs={{ span: 12 }} className={styles.head}>
                    <h5 >ホーム</h5>
                </Col>
                <Col md={{ span: 11, offset: 1 }}>
                    {props.locationStatus === 0 ?
                        <Button role="alert" className="alert alert-primary">位置情報取得中...</Button>
                        :
                        props.locationStatus === 1 ?
                            <Button role="alert" className="alert alert-primary">位置情報の取得に成功しました</Button>
                            :
                            props.locationStatus === 2 ?
                                <Button role="alert" className="alert alert-danger">位置情報の取得に失敗しました</Button>
                                :
                                false
                    }
                </Col>
                {
                    props.workStatus === "WITHIN" &&
                    <Col md={{ span: 11, offset: 1 }}>
                        勤務中
                    </Col>
                }
                {/* TODO: */}
                {props.workStatus === "ENDTIME" &&
                    <Col md={{ span: 11, offset: 1 }}>
                        本日分の勤怠入力は完了しています(*^^*)
                </Col>
                }
                {props.workStatus === "PASSED" &&
                    <Col md={{ span: 11, offset: 1 }}>
                        <p>退勤未入力があります ⇨ {props.workDate}</p>
                        <p>手動入力して修正してください</p>
                    </Col>
                }
                {props.workStatus === "PASSED" &&

                    <Col md={{ span: 11, offset: 1 }}>
                        <Button
                            variant='danger'
                            onClick={props.openErrorModal}
                            className={styles.attendanceErrorButton}
                        >未打刻の退勤を編集</Button>
                        <Modal
                            isOpen={props.modalIsOpen}
                            onRequestClose={props.closeErrorModal}
                            contentLabel="punch out error"
                            shouldCloseOnOverlayClick={true}
                            style={{
                                overlay: {
                                    zIndex: 999999999
                                },
                                content: {
                                    border: 'none',
                                    maxWidth: '500px',
                                    maxHeight: '400px',
                                    margin: 'auto'
                                },

                            }}
                        >
                            <div>
                                <div className="form-group">
                                    {props.workDate}の退勤が入力されていません。入力してください。
                                    <Form.Group controlId="exampleForm.ControlSelect1" style={{ display: "none" }}>
                                        <Form.Label>日付</Form.Label>
                                        <Form.Control as="select"
                                            name="workDate"
                                            value={props.workDate}
                                            onChange={props.handleChange}>
                                            <option value={props.workDate}>{props.workDate}</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>時間</Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="manualWorkTime"
                                            value={props.manualWorkTime}
                                            onChange={props.handleChange}
                                        />
                                    </Form.Group>
                                    {/* <Button className="btn btn-secondary my-2" onClick={props.handleEditTime} >手動入力</Button> */}
                                    <Button
                                        className="btn btn-secondary my-2"
                                        onClick={props.handleCalcManualBreakTime} >手動入力</Button>

                                    <p></p>
                                    <Button className="btn btn-secondary my-2" onClick={props.closeErrorModal} >閉じる</Button>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={props.manualBreakTimeModalIsOpen}
                            onRequestClose={props.closeManualBreakTimeModal}
                            contentLabel="Example Modal"
                            shouldCloseOnOverlayClick={true}
                            style={{
                                overlay: {
                                    zIndex: 999999999
                                },
                                content: {
                                    border: 'none',
                                    maxWidth: '500px',
                                    maxHeight: '400px',
                                    margin: 'auto'
                                },

                            }}
                        ><div>
                                <div className="form-group">
                                    <p>{props.workDate}の退勤を入力しますか？(手動)</p>
                                    <p>休憩時間に変更がある場合は入力してください</p>
                                    <Form.Group >
                                        <Form.Label>休憩時間(分)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="breakTime"
                                            value={props.breakTime}
                                            onChange={props.handleChange}
                                        />
                                    </Form.Group>
                                    <Button
                                        variant='info'
                                        onClick={props.handleEditTime}
                                        className={styles.attendanceButton}
                                    >退勤</Button>
                                    <p></p>
                                    <Button className="btn btn-secondary my-2" onClick={props.closeManualBreakTimeModal} >閉じる</Button>
                                </div>
                            </div></Modal>
                    </Col>
                }
                <Col md={{ span: 11, offset: 1 }}>
                    <h2 className={styles.date}>
                        <Moment
                            locale="ja"
                            format="YYYY/MM/DD (ddd)"
                        >
                            {props.date}
                        </Moment>
                        <Moment format="HH:mm:ss">{props.time}</Moment>
                    </h2>
                </Col>
                {/* ボタン系 */}
                <Col md={{ span: 6, offset: 1 }}>
                    <Row>
                        <Col md={{ span: 6 }} className={styles.attendanceButtonColumns}>
                            <Button
                                variant='info'
                                onClick={props.handleSetTime}
                                className={styles.attendanceButton}
                                disabled={
                                    props.workStatus === "WITHIN"
                                    || props.workStatus === "PASSED"
                                    || props.workStatus === "ENDTIME"
                                }
                            >出勤</Button>
                        </Col>
                        <Col md={{ span: 6 }} className={styles.attendanceButtonColumns}>
                            <Button
                                variant='info'
                                onClick={props.handleCalcBreakTime}
                                className={styles.attendanceButton}
                                disabled={
                                    props.workStatus === "PASSED"
                                    || props.workStatus === "ENDTIME"
                                    || props.workStatus === null
                                    || props.workStatus === "STARTTIME"
                                }
                            >退勤</Button>
                        </Col>
                        <Modal
                            isOpen={props.breakTimeModalIsOpen}
                            onRequestClose={props.closeBreakTimeModal}
                            contentLabel="Example Modal"
                            shouldCloseOnOverlayClick={true}
                            style={{
                                overlay: {
                                    zIndex: 999999999
                                },
                                content: {
                                    border: 'none',
                                    maxWidth: '500px',
                                    maxHeight: '400px',
                                    margin: 'auto'
                                },

                            }}
                        ><div>
                                <div className="form-group">
                                    <p>{props.workDate}の退勤を入力しますか？</p>
                                    <p>休憩時間に変更がある場合は入力してください</p>
                                    <Form.Group >
                                        <Form.Label>休憩時間(分)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="breakTime"
                                            value={props.breakTime}
                                            onChange={props.handleChange}
                                        />
                                    </Form.Group>
                                    <Button
                                        variant='info'
                                        onClick={props.handleSetTime}
                                        className={styles.attendanceButton}
                                    >退勤</Button>
                                </div>
                            </div></Modal>
                    </Row>
                </Col>
                {/* TODO: コメントとか */}
                <Col md={{ span: 6 }}>
                    {/* <Row>
                        <Col md={{ span: 12 }} >
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>コメント</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="3"
                                    name="comment"
                                    value={props.comment}
                                    onChange={props.handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row> */}
                </Col>

                <Col md={{ span: 4 }}></Col>
            </Row>
        }
    </div>
);

export default Attendance;
