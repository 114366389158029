import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendRegisterUserMail: (userId, authority) => {
            return dispatch(userActions.sendRegisterUserMail(userId, authority));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
