import React, { Component } from "react";
import Home from "./presenter";
class Container extends Component {

    state = {
        attendance: true,
        timecard: false,
        settings: false,
    }

    render() {
        return (
            <Home
                {...this.props}
                {...this.state}
                switchSS={this._switchSS}
            />
        );
    }

    _switchSS = (name, value, event) => {
        this.setState({
            attendance: false,
            timecard: false,
            settings: false,
        })

        this.setState({
            [name]: value
        })
    }


}

export default Container;
