import React, { Component } from "react";
import RegisterForm from "./presenter";
import queryString from "query-string";

class Container extends Component {
    state = {
        userId: "",
        registUserName: "",
        registPassword: "",
        passwordConfirm: "",
        loading: false,
        verifyStatus: "",
    }

    render() {
        return (
            <RegisterForm
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
            />
        );
    }

    componentDidMount = async () => {
        // verify
        const { verifyRegisterUser } = this.props;
        const parsed = queryString.parse(this.props.location.search);
        const result = await verifyRegisterUser(parsed.param);
        console.log(result);

        this.setState({ verifyStatus: result.status, userId: result.userId })
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { registerUser } = this.props;
        const { userId, userName, password, passwordConfirm } = this.state;
        if (password === passwordConfirm) {
            event.preventDefault();
            const result = await registerUser(userId, userName, password, "user");

            if (result) {
                alert("ユーザー登録成功しました、ログイン画面へ遷移します");
                this.props.history.push('/login');
            }
        } else {
            alert("パスワードが一致していません。");
        }

        this.setState({ loading: false });
    }
}

export default Container;
