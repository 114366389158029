import React, { Component } from "react";
import Loading from "./presenter";

class Container extends Component {
    render() {
        return (
            <Loading />
        );
    }
}

export default Container;
