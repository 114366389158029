import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MediaQuery from "react-responsive";
import { faBars, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'
import Button from "react-bootstrap/Button";

const DashboardHeader = props => (
    <Navbar
        collapseOnSelect
        fixed="top"
        className={classNames(styles.customNavbar,
        )}
        expand="sm"
    >
        {/* mobile表示時のsidebar開閉ハンバーガーメニュー */}
        <div className={styles.onlyMobile}>
            <div className={styles.togglerWrap}>
                <Button className={styles.toggler} onClick={props.onToggle.bind(this, props.expanded, !props.mobileExpanded)}>
                    <FontAwesomeIcon icon={faBars} className={styles.icons} />
                </Button>
            </div>
        </div>

        {/* ブランドロゴ */}
        <Link to="/" className={classNames(styles.brand, "navbar-brand")}>
            <img src={`${process.env.PUBLIC_URL}/logo.svg`} className={styles.logo} alt="logo" />
            <span style={{
                letterSpacing: "0px",
                fontSize: "12px",
            }}>(α ver.)</span>
        </Link>

        <Nav className={classNames("mr-auto", styles.mrAuto)}></Nav>
        {/* ユーザーメニュー */}
        <div className={styles.userMenuWrap}>
            <NavDropdown title={<img src={`${process.env.PUBLIC_URL}/user-icon.svg`} className={styles.userMenu} alt="logo" />} id="nav-dropdown">
                <LinkContainer to="/profile" >
                    <NavDropdown.Item eventKey="4.2" className={styles.navItems}>
                        <FontAwesomeIcon icon={faUser} className={styles.icons} /> <span className={"text-break"}>{props.userInfo.userName}</span>
                    </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/logout" onClick={props.handleLogout}>
                    <NavDropdown.Item eventKey="4.3" className={styles.navItems}>
                        <FontAwesomeIcon icon={faSignOutAlt} className={styles.icons} /> <span>Logout</span>
                    </NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </div>
    </Navbar>
);

export default DashboardHeader;
