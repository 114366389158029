import { connect } from "react-redux";
import Container from "./container";
const mapStateToProps = state => {
    const { user:
        { userInfo: { userId, initLoggedIn }, isLoggedIn },

    } = state;
    return {
        userId,
        initLoggedIn,
        isLoggedIn,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
