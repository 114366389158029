import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "components/Header";
import DashboardHeader from "components/DashboardHeader";
import Home from "components/Home";
import Login from "components/Login";
import styles from "./styles.scss";
import Dashbord from "components/Dashbord";
import Footer from 'components/Footer';
import Register from "components/Register";
import PasswordReset from "components/PasswordReset";
import PasswordResetForm from "components/PasswordResetForm";
import RegisterForm from "components/RegisterForm";
import AdminRegister from "components/AdminRegister";
import AdminRegisterForm from "components/AdminRegisterForm";
import { ToastContainer } from 'react-toastify';

const App = props => (
    <div className={styles.contentsWrap}>
        <ToastContainer />
        {props.isLoggedIn ?
            <div>
                <DashboardHeader {...props} />
                <PrivateRoutes {...props} />
            </div>
            :
            <div>
                <Header {...props} />
                <PublicRoutes {...props} />
                <Footer />
            </div>
        }
    </div>
);

const PrivateRoutes = props => (
    <Switch>
        <Route exact path="/" render={() => <Dashbord {...props} />} />
        <Route render={() => <Dashbord {...props} />} />
    </Switch>
);

const PublicRoutes = props => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/register-form" component={RegisterForm} />
        <Route path="/admin-register" component={AdminRegister} />
        <Route path="/admin-register-form" component={AdminRegisterForm} />
        <Route path="/password-reset" component={PasswordReset} />
        <Route path="/password-reset-form" component={PasswordResetForm} />
        <Route component={Home} />
    </Switch>
);

export default App;
