import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "stores/user";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        challengeLogin: (userId, password) => {
            return dispatch(userActions.challengeLogin(userId, password));
        },
        getUserInfo: (userId) => {
            return dispatch(userActions.getUserInfo(userId));
        },
        googleSignin: () => {
            dispatch(userActions.googleSignin());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
