import React, { Component } from "react";
import PasswordResetForm from "./presenter";
import queryString from 'query-string';

class Container extends Component {
    state = {
        password: "",
        confirmPassword: "",
        loading: true,
        verifyParam: "",
    }

    render() {
        return (
            <PasswordResetForm
                {...this.props}
                {...this.state}
                handleChange={this._change}
                handleSubmit={this._submit}
            />
        );
    }

    componentDidMount = async () => {
        // paramチェック
        console.log(this.props);
        const parsed = queryString.parse(this.props.location.search);
        this.setState({ verifyParam: parsed.param });
        console.log(parsed.param);
        const { passwordResetVerify } = this.props;
        const result = await passwordResetVerify(parsed.param);
        this.setState({ loading: false, verifyStatus: result.status })
    }

    _change = event => {
        const { target: { name, value } } = event

        this.setState({
            [name]: value
        });
    };

    _submit = async event => {
        const { password, confirmPassword, verifyParam } = this.state;
        const { passwordReset } = this.props;

        if (confirmPassword === "" || password === "") {
            alert('入力してください');
        } else if (confirmPassword !== password) {
            alert('確認用のパスワードが間違っています')
        } else {
            const result = await passwordReset(password, verifyParam);
            if (result) {
                alert('password reset succeed');
            } else {
                alert('password reset failed');
            }
        }

    }
}

export default Container;
